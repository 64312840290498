import { StyleSheet, View } from '@react-pdf/renderer';
import { useTranslations } from '@vocab/react';
import { useMemo } from 'react';
import translations from '../../../.vocab';
import { PdfButtonIcon } from '../components/button-icon';
import { PdfCard } from '../components/card';
import { PdfIconPromote, PdfIconTip } from '../components/icons';
import { PdfHeadingH3, PdfText, PdfTextLink } from '../components/text';

const styles = StyleSheet.create({
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '64px',
    backgroundColor: '#fff',
    maxWidth: '660px',
    margin: '0 auto',
  },

  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '64px',
    gap: '32px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },

  cardInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '30px',
    gap: '24px',
  },
  iconContainer: {
    flexShrink: 0,
  },
  cta: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
  },
  ctaIcon: {
    color: '#ffffff',
    fill: '#ffffff',
  },
  tipText: {
    paddingTop: '4px',
  },
  aboutContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
});

export const TalPdfFooter = ({ fullURL }: { fullURL: string }) => {
  const { t } = useTranslations(translations);
  const tips = useMemo(
    () => [
      t('Mention these top three drivers of attraction in your job ad.'),
      t(
        `Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.`,
      ),
      t(
        'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
      ),
    ],
    [t],
  );
  return (
    <View style={styles.footerContainer}>
      <View style={styles.sectionContainer} break>
        <PdfText>
          {t(
            "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
          )}
        </PdfText>
        <View style={styles.cardContainer}>
          {tips.map((tip, index) => (
            <PdfCard key={index}>
              <View style={styles.cardInnerContainer}>
                <PdfIconTip />

                <PdfText style={styles.tipText}>{tip}</PdfText>
              </View>
            </PdfCard>
          ))}
        </View>
        <View style={styles.cta}>
          <View style={styles.iconContainer}>
            <PdfButtonIcon>
              <PdfIconPromote style={styles.ctaIcon} />
            </PdfButtonIcon>
          </View>
          <View style={{ paddingTop: '2px' }}>
            <PdfText>
              {t(
                'Visit www.seek.com.au/tal to discover a full list of detailed drivers.',
                {
                  Link: () => (
                    <PdfTextLink src={fullURL}>
                      {t("SEEK's Talent Attraction Lab")}
                    </PdfTextLink>
                  ),
                },
              )}
            </PdfText>
          </View>
        </View>
      </View>
      <View style={styles.aboutContainer}>
        <PdfHeadingH3>{t('About this research')}</PdfHeadingH3>
        <PdfText>
          {t(
            'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
          )}{' '}
          {t(
            'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}',
            {
              Link: () => (
                <PdfTextLink src={fullURL}>
                  {t("SEEK's Talent Attraction Lab")}
                </PdfTextLink>
              ),
            },
          )}
        </PdfText>
      </View>
    </View>
  );
};
