import type { Locale } from '@seek/cmsu-cms-connect';
import { prefixUrlWithLanguage } from '../../helpers/prefixUrlWithLanguage';

type BreadCrumbItem = {
  url?: string;
  text: string;
};

const capitaliseFirstLetter = (str: string): string =>
  str[0].toUpperCase() + str.slice(1).toLowerCase();

const nameToCapitalised = (str: string): string =>
  str.split(' ').map(capitaliseFirstLetter).join(' ');

const slugToTitle = (str: string): string =>
  str
    .split('-')
    .map((part) => capitaliseFirstLetter(part))
    .join(' ');

export type BreadcrumbItemsProps = {
  locale: Locale;
  section: string;
  sectionDisplayName?: string | null;
  title?: string;
  parentCategoryName?: string;
  parentCategorySlug?: string;
  categoryName?: string;
  categorySlug?: string;
  parentSection?: string;
  parentSectionDisplayName?: string | null;
};

/**
 * Also handles first letter capitalisation for section names
 */
export const getBreadcrumbItems = ({
  locale,
  section,
  title = '',
  parentCategoryName,
  parentCategorySlug,
  categoryName,
  categorySlug,
  sectionDisplayName,
  parentSection,
  parentSectionDisplayName,
}: BreadcrumbItemsProps) => {
  const items: BreadCrumbItem[] = [
    {
      text:
        (sectionDisplayName && nameToCapitalised(sectionDisplayName)) ||
        (section && slugToTitle(section)),
      url: prefixUrlWithLanguage({
        url: `${parentSection ? `/${parentSection}` : ''}/${section}`,
        locale,
      }),
    },
  ];

  if (parentSection) {
    items.splice(0, 0, {
      text:
        (parentSectionDisplayName &&
          nameToCapitalised(parentSectionDisplayName)) ||
        (parentSection && slugToTitle(parentSection)),
      url: prefixUrlWithLanguage({
        url: `/${parentSection}`,
        locale,
      }),
    });
  }

  if (categoryName && categorySlug) {
    items.push({
      text: categoryName || '',
      url: prefixUrlWithLanguage({
        url: `${
          parentSection ? `/${parentSection}` : ''
        }/${section}/category/${categorySlug}`,
        locale,
      }),
    });

    if (parentCategoryName && parentCategoryName.trim().length) {
      items.splice(1, 0, {
        text: parentCategoryName,
        url: prefixUrlWithLanguage({
          url: `/${section}/category/${parentCategorySlug}`,
          locale,
        }),
      });
    }
  }

  if (title.trim().length) {
    items.push({
      text: title,
    });
  }
  items[items.length - 1].url = undefined;
  return items;
};
