import type {
  CTextSize,
  IconBlockFragment,
  IconBlockGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Stack } from 'braid-design-system';
import React from 'react';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';

import { renderIcon } from './renderer';
import { getBoxBackgroundProps } from '../PageComponent/helper';

type Props = Pick<IconBlockFragment, 'heading' | 'icon' | 'paragraph'> &
  Pick<
    IconBlockGroupFragment,
    'blockBackground' | 'iconAlignment' | 'inlineBlockItem'
  >;

export const IconBlock = ({
  icon,
  heading,
  paragraph,
  blockBackground,
  iconAlignment,
  inlineBlockItem,
}: Props) => (
  <Box
    {...getBoxBackgroundProps(blockBackground)}
    height="full"
    padding="gutter"
    data={{ testid: 'IconBlockTestId' }}
    {...(inlineBlockItem && { paddingLeft: 'none', paddingBottom: 'none' })}
  >
    {inlineBlockItem ? (
      <Box display="flex">
        {Boolean(icon) && (
          <Box
            display="flex"
            alignItems={iconAlignment || 'flexStart'}
            marginRight="gutter"
          >
            <div
              {...(inlineBlockItem &&
                !heading?.text && {
                  style: {
                    marginTop: getIconMargin(
                      paragraph?.size,
                      paragraph?.Paragraph_text?.raw.children[0].type,
                    ),
                    marginRight: '-7px',
                  },
                })}
            >
              {renderIcon(icon)}
            </div>
          </Box>
        )}
        <Box width="full" justifyContent="flexStart">
          <Stack space="small">
            {Boolean(heading) && (
              <Heading {...heading} text={heading?.text || ''} />
            )}
            {paragraph && (
              <Paragraph
                content={paragraph.Paragraph_text?.raw}
                align={paragraph.align}
                tone={paragraph.tone}
                size={paragraph.size}
              />
            )}
          </Stack>
        </Box>
      </Box>
    ) : (
      <Stack space="large" align="center">
        {renderIcon(icon)}
        {Boolean(heading) && (
          <Heading {...heading} text={heading?.text || ''} />
        )}
        {paragraph?.Paragraph_text && (
          <Paragraph
            content={paragraph.Paragraph_text.raw}
            align={paragraph.align}
            tone={paragraph.tone}
            size={paragraph.size}
          />
        )}
      </Stack>
    )}
  </Box>
);

const getIconMargin = (size: CTextSize | null | undefined, type: string) => {
  if (type !== 'paragraph') {
    switch (type) {
      case 'heading-two':
        return '29px';
      case 'heading-three':
        return '15px';
      case 'heading-four':
        return '18px';
      default:
    }
  }
  switch (size) {
    case 'small':
    case 'xsmall':
      return '-9px';
    case 'large':
    default:
      return '-6px';
  }
};
