import type { CustomFontSize } from '@seek/cmsu-cms-connect';

// This font size mapper is used to convert the custom font size to a number for non-braid components
// The mapping value is yet to be finalised, currently in use is 5rem for numerical block and '3rem' at Heading

export const toCustomFontSize = (size?: CustomFontSize | null): number => {
  switch (size) {
    case 'xxxlarge':
      return 6;
    case 'xxlarge':
      return 5.5;
    case 'xlarge':
      return 5;
    case 'large':
      return 4;
    case 'standard':
      return 3;
    case 'small':
      return 2;
    case 'xsmall':
      return 1;
    case 'xxsmall':
      return 0.875;
    case 'xxxsmall':
      return 0.75;
    default:
      return 3;
  }
};
