import { Document, Font, Page, StyleSheet, View } from '@react-pdf/renderer';
import { VocabProvider } from '@vocab/react';
import { TalPdfFooter } from './footer';
import { TalPdfHeader } from './header';
import { TalPdfSummary, type PdfDataSummary } from './summary';

Font.register({
  family: 'Noto Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/notosans/v38/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyD9A99d41P6zHtY.ttf',
      fontWeight: 400,
    },

    {
      src: 'https://fonts.gstatic.com/s/notosans/v38/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyAaBN9d41P6zHtY.ttf',
      fontWeight: 700,
    },
  ],
});
Font.register({
  family: 'Noto Sans Thai',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RtpzF-QRvzzXg.ttf',
      fontWeight: 400,
    },

    {
      src: 'https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU3NqpzF-QRvzzXg.ttf',
      fontWeight: 700,
    },
  ],
});
const style = StyleSheet.create({
  container: {
    maxWidth: '660px',
    margin: '60px auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const PdfDoc = ({
  data,
  language,
  fullURL,
}: {
  data: PdfDataSummary[];
  language: string;
  fullURL: string;
}) => (
  <VocabProvider language={language}>
    <Document>
      <Page
        size="A2"
        style={{
          fontFamily: language === 'th-TH' ? 'Noto Sans Thai' : 'Noto Sans',
        }}
      >
        <View>
          <TalPdfHeader />
          <View style={style.container}>
            <TalPdfSummary data={data} />
            <TalPdfFooter fullURL={fullURL} />
          </View>
        </View>
      </Page>
    </Document>
  </VocabProvider>
);
