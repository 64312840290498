import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetArticlesByCategoryPaginationDocument,
  GetArticlesDocument,
  GetAuthorWithArticlesDocument,
  GetBannerItemDocument,
  GetCategoriesWithLatestArticlesDocument,
  GetFormDataDocument,
  GetParentSectionDocument,
  GetPreviewDataDocument,
  InvestorOptions,
  Locale,
  Stage,
  getPageWithEntities,
  type Category,
  type GetArticlesByCategoryPaginationQuery,
  type GetArticlesQuery,
  type GetAuthorWithArticlesQuery,
  type GetBannerItemQuery,
  type GetCategoriesWithLatestArticlesQuery,
  type GetFormDataQuery,
  type GetPagesQuery,
  type GetPagesWithEntitiesQueryVariables,
  type GetPreviewDataQuery,
  type LinkFragment,
  type PageWhereInput,
} from '@seek/cmsu-cms-connect';
import {
  TAL,
  TAL_DATA_LAB_SLUG,
  type DatalabComponent,
  type DatalabSurvey,
} from '@seek/cmsu-components/src/modules/Talent-Attraction-Lab/datalabHelper';
import type { LinkToFragment } from '@seek/cmsu-components/src/modules/types';
import type { SiteName } from '@seek/melways-sites';
import { json, type LoaderFunction } from 'react-router-dom';
import { getLocales } from './client/shared/utils/getLocales';
import { getRecordsToSkip, pageConfig } from './client/shared/utils/Pagination';
import {
  formatBlocksForRendering,
  getArticleFromData,
  getAuthorWithArticlesFromData,
  getPreviewDocumentData,
  getRouteInfo,
  getSectionQuery,
  isContentNA,
  isSlugMatchesLastSection,
  transformAwardSiteSlug,
  type CategoryLocalization,
} from './helpers/loadersHelper';
import { filterObjectsByContentLocaleOrDefault } from './helpers/localizationHelper';
import type {
  AsxAnnouncementsInvestorInformation,
  DividendInvestorInformation,
  SharePriceInvestorInformation,
} from './helpers/pageRenderers';
import { loadDatalabData, type DatalabLoaderProps } from './loadersTAL';
import { internalError, notFound } from './responses';
import type { RouteProps } from './routes';
import { weblinkLoader } from './server/weblinkLoaders';
import { getHygraphLocales } from './client/shared/utils/helper';

type CategoryQueryFilter = {
  client: ApolloClient<NormalizedCacheObject>;
  slug: string | undefined;
  siteName: string;
  locale: Locale;
  sections: string[];
  isDraft: boolean;
  pageNumber?: string | undefined;
};

type sectionData = {
  __typename: string;
  id: string;
  parentSection?: {
    sectionName: string;
  };
};

type sectionsQuery = {
  client: ApolloClient<NormalizedCacheObject>;
  sectionIds?: string[];
};

type QueryProps = {
  client: ApolloClient<NormalizedCacheObject>;
  hygraphLocale: Locale;
  hygraphSite: SiteName;
  isDraft: boolean;
};

const filterDocumentBy = (
  isPreviewMode: boolean,
  documentFilterParam: string | undefined,
) => {
  if (isPreviewMode) {
    // Query hygraph - for article preview
    return { id: decodeURIComponent(documentFilterParam || '') };
  }
  // Query hygraph - for regular view
  return { slug: decodeURIComponent(documentFilterParam || '') };
};

/**
 * This loader is used to fetch the preview data for article and page
 * @param config
 * @param client
 */
export const previewDocumentLoader =
  ({ config, client }: RouteProps): LoaderFunction =>
  async ({ params }) => {
    // if there is no Article or Page id provided
    if (!params.id) {
      throw notFound(params.slug);
    }

    // if the Draft content needs to be displayed, need to use Token
    const stage = config.isDraft ? 'DRAFT' : 'PUBLISHED';

    // use single query to obtain article or page
    const variables = {
      whereArticle: {
        id: params.id,
      },
      wherePage: {
        id: params.id,
      },
      stage,
    };

    // Fetch the article if it exists.
    const { data, error } = await client.query<GetPreviewDataQuery>({
      query: GetPreviewDataDocument,
      variables,
      fetchPolicy: 'network-only',
    });

    if (error) {
      throw internalError(error);
    }

    // If the page or article are not available, redirect user to NotFound Page
    if (
      !data.article?.localizations?.length &&
      !data.page?.localizations?.length
    ) {
      throw notFound(params.slug);
    }

    const previewData = getPreviewDocumentData(config, data);

    return json(previewData);
  };

/**
 * This loader is used to fetch an article content from HyGraph.
 * @param config
 * @param client
 */
export const articleLoader =
  (
    { config, client, clientTAL }: RouteProps,
    isArticlePreview = false,
  ): LoaderFunction =>
  async ({ request, params }) => {
    // Extract variables
    const {
      site,
      hygraphLocale,
      hygraphSite,
      language,
      pathname,
      isDraft,
      routeArray,
      section,
      melwaysLocale,
    } = config;

    const slugName = isArticlePreview ? 'article-preview' : 'article';
    const { sections } = getRouteInfo(routeArray, slugName);
    const { slug } = params;

    const variables = {
      where: {
        sites_some: { name: hygraphSite },
        // if multi locales article cannot be found, the reason could be the section name's localisation.
        // Check the locale value of section name, if not matching with the sectionDefault value, no article returns
        // For example; hiring-advice's Thai locale is different, so that locale value must be sent
        category: {
          relatedCategorySection: getSectionQuery(sections.reverse()),
        },
        ...filterDocumentBy(isArticlePreview, slug),
      },
      locale: getHygraphLocales(hygraphLocale),
      localesWithDefault: [hygraphLocale, Locale.Default],
      stage: isDraft ? Stage.Draft : Stage.Published,
    };

    // Fetch the article if it exists.
    const { data, error } = await client.query<GetArticlesQuery>({
      query: GetArticlesDocument,
      variables,
      fetchPolicy: 'network-only',
    });
    if (error) {
      throw internalError(error);
    }

    // If the content is not available, redirect user to NotFound Page (CU-762).
    if (!data.articles.length) {
      throw notFound(slug);
    }

    const article = getArticleFromData(data, hygraphLocale);
    // If the content is not available, redirect to NotFound Page (CU-762).
    if (!article || isContentNA(article.content?.text)) {
      throw notFound(slug);
    }
    // ensure we have article now
    const locales = getLocales(
      site,
      pathname.replace(`/${language}/`, ''),
      data.articles[0].sites,
      data.articles[0].localizations,
    );
    // If the article is under about section, eg: about/news, load the data for subscription form and contact us

    const aboutSubFormContactUs = await getAboutSubForm_ContactUs(section, {
      hygraphSite,
      hygraphLocale,
      isDraft,
      client,
    });
    const isTAL = article.category?.slug === 'talent-attraction-advice';
    const additionalData = {
      datalabSurvey: isTAL
        ? await loadDatalabData({
            clientTAL,
            locale: melwaysLocale,
            request,
            requireSurveyResult: false,
          })
        : undefined,
    };

    if (Boolean(aboutSubFormContactUs)) {
      const updatedArticle = {
        ...article,
        ...aboutSubFormContactUs,
        additionalData: {
          ...additionalData,
        },
      };
      return json({ ...updatedArticle, locales });
    }

    return json({
      ...article,
      additionalData: {
        ...additionalData,
      },
      locales,
    });
  };

/**
 * This loader is used to fetch an author with articles content from HyGraph.
 * @param config
 * @param client
 */
export const authorWithArticlesLoader =
  ({ config, client }: RouteProps): LoaderFunction =>
  async ({ params }) => {
    // Extract variables
    const { site, hygraphLocale, hygraphSite, language, pathname, isDraft } =
      config;

    const { slug, pageNumber } = params;

    const skip = getRecordsToSkip(pageNumber);

    // Query hygraph
    const variables = {
      slug: decodeURIComponent(slug || ''),
      site: hygraphSite,
      locale: getHygraphLocales(hygraphLocale),
      localesWithDefault: [hygraphLocale, Locale.Default],
      stage: isDraft ? Stage.Draft : Stage.Published,
      first: pageConfig.pageSize,
      skip,
    };

    // Fetch the author if it exists.
    const { data, error } = await client.query<GetAuthorWithArticlesQuery>({
      query: GetAuthorWithArticlesDocument,
      variables,
      fetchPolicy: 'network-only',
    });

    if (error) {
      throw internalError(error);
    }

    // If the content is not available, redirect user to NotFound Page (CU-762).
    if (!data.authors.length) {
      throw notFound(slug);
    }

    const authors = getAuthorWithArticlesFromData(data, hygraphLocale);

    // If the content is not available, redirect to NotFound Page (CU-762).
    if (!authors || !authors.name) {
      throw notFound(slug);
    }

    // ensure we have article now
    const locales = getLocales(
      site,
      pathname.replace(`/${language}/`, ''),
      data.authors[0].sites,
      data.authors[0].localizations,
    );

    return json({ ...authors, locales });
  };
type LoadLinkDataProps = {
  client: ApolloClient<NormalizedCacheObject>;
  pageData: GetPagesQuery['pages'][0];
};
type LoadExternalDataProps = {
  pageData: GetPagesQuery['pages'][0];
  pageNumber?: string | number;
};

const loadExternalData = async ({
  pageData,
  pageNumber,
}: LoadExternalDataProps) => {
  for (const container of pageData.containers) {
    for (const block of container.blocks) {
      for (const item of block.items) {
        if (item.__typename === 'CCustomComponent') {
          if (item.data?.__typename === 'InvestorInformation') {
            if (item.data.options === 'Dividends') {
              item.data = {
                ...item.data,
                externalResources: await weblinkLoader.loadDividends(),
              } as DividendInvestorInformation;
            } else if (item.data.options === 'SharePrice') {
              item.data = {
                ...item.data,
                externalResources: await weblinkLoader.loadSharePrice(),
              } as SharePriceInvestorInformation;
            } else if (
              item.data.options === InvestorOptions.AsxAnnouncements ||
              item.data.options === InvestorOptions.LatestAsxAnnouncements
            ) {
              item.data = {
                ...item.data,
                externalResources:
                  await weblinkLoader.loadLatestAsxAnnouncements({
                    pageNumber,
                  }),
              } as AsxAnnouncementsInvestorInformation;
            }
          }
        }
      }
    }
  }
};

const mapDatalabSurveyDataToPageData = async ({
  pageData,
  datalabData,
}: {
  pageData: GetPagesQuery['pages'][0];
  datalabData: DatalabSurvey;
}) => {
  let data: DatalabSurvey | undefined;
  for (const container of pageData.containers) {
    for (const block of container.blocks) {
      for (let i = 0; i < block.items.length; i++) {
        if (
          block.items[i].__typename === 'Datalab' ||
          block.items[i].__typename === 'CBanner' // For the tal-landing page for the banner custom component
        ) {
          if (!data) data = datalabData;
          block.items[i] = {
            ...block.items[i],
            datalabSurvey: data,
          } as DatalabComponent;
        }
      }
    }
  }
};

const getPageQueryVariables = (
  where: PageWhereInput,
  isDraft: boolean,
  hygraphLocale: Locale,
) => {
  const stage = isDraft ? Stage.Draft : Stage.Published;
  const locale = getHygraphLocales(hygraphLocale);
  return {
    where,
    locale,
    stage,
  };
};
type loadPageDataProps = {
  client: ApolloClient<NormalizedCacheObject>;
  variables: GetPagesWithEntitiesQueryVariables;
  sections: string[] | undefined;
  slug: string | undefined;
  pageNumber?: string | number;
};

const loadPageData = async ({
  client,
  variables,
  sections,
  slug,
  pageNumber,
  clientTAL,
  locale,
  request,
}: loadPageDataProps & DatalabLoaderProps) => {
  let payload;
  let datalabData;

  const isDatalabPage =
    (sections && sections.includes(TAL) && slug === TAL_DATA_LAB_SLUG) ||
    slug === TAL;

  try {
    const responses = await Promise.all([
      getPageWithEntities({
        client,
        variables,
        formatBlocksForRendering,
      }),
      isDatalabPage
        ? loadDatalabData({
            clientTAL,
            locale,
            request,
            requireSurveyResult: slug !== TAL,
          })
        : undefined,
    ]);
    payload = responses[0];
    datalabData = responses[1];
  } catch (e: any) {
    if (e.code === '404') {
      throw notFound(slug);
    } else {
      throw internalError();
    }
  }

  // Load External Data (e.g. WebLinks)
  await loadExternalData({ pageData: payload, pageNumber });
  await loadLinkParentSectionData({ client, pageData: payload });

  if (datalabData)
    mapDatalabSurveyDataToPageData({
      pageData: payload,
      datalabData,
    });

  return json(payload);
};

export const pageLoader =
  ({ config, client, clientTAL }: RouteProps): LoaderFunction =>
  async ({ request }) => {
    // Extract variables
    const {
      country,
      hygraphSite,
      hygraphLocale,
      melwaysLocale,
      isDraft,
      routeArray,
    } = config;
    const { slug, pageNo, sections } = getRouteInfo(routeArray);

    // Apply manual transformation for award site slugs to match their Hygraph formatting
    const formattedSlug = transformAwardSiteSlug(sections, slug, country);

    if (isSlugMatchesLastSection(sections, formattedSlug)) {
      throw notFound(formattedSlug);
    }

    const where = {
      slug: formattedSlug,
      sites_some: { name: hygraphSite },
    };
    const variables = getPageQueryVariables(where, isDraft, hygraphLocale);

    return await loadPageData({
      client,
      variables,
      sections,
      slug: formattedSlug,
      pageNumber: pageNo,
      clientTAL,
      locale: melwaysLocale,
      request,
    });
  };

export const pageWithoutSectionLoader =
  ({ config, client, clientTAL }: RouteProps): LoaderFunction =>
  async ({ request, params }) => {
    // Extract variables
    const {
      hygraphSite,
      hygraphLocale,
      melwaysLocale,
      isDraft,
      environment,
      routeArray,
    } = config;

    const { slug, sections } = getRouteInfo(routeArray);

    if (isSlugMatchesLastSection(sections, slug)) {
      throw notFound(slug);
    }
    // Below condition is to cater for local testing where slug is empty
    if (!Boolean(params.slug?.trim()) && environment === 'development')
      throw notFound(params.slug);

    const where = {
      slug: params.slug,
      sites_some: { name: hygraphSite },
      relatedPageSection: null,
    };

    const variables = getPageQueryVariables(where, isDraft, hygraphLocale);

    return await loadPageData({
      client,
      variables,
      sections,
      slug: params.slug,
      clientTAL,
      locale: melwaysLocale,
      request,
    });
  };

export const categoryLoader =
  ({ config, client }: RouteProps): LoaderFunction =>
  async ({ params }) => {
    // Extract variables
    const { hygraphSite, hygraphLocale, routeArray, isDraft, section } = config;

    const { sections } = getRouteInfo(routeArray, 'category');

    const { slug, pageNumber } = params;

    sections.reverse();
    const category = await getCategoryWithLatestArticlesForChildCategoies({
      client,
      slug,
      siteName: hygraphSite,
      locale: hygraphLocale,
      sections,
      isDraft,
    });

    const articles = await getArticlesByCategory({
      client,
      slug,
      siteName: hygraphSite,
      locale: hygraphLocale,
      sections,
      isDraft,
      pageNumber,
    });

    // If the article is under about section, eg: about/news, load the data for subscription form and contact us

    const aboutSubFormContactUs = await getAboutSubForm_ContactUs(section, {
      hygraphSite,
      hygraphLocale,
      isDraft,
      client,
    });

    // Return response
    if (
      !category ||
      (category.children.length === 0 &&
        articles.articlesConnection.edges.length === 0)
    ) {
      throw notFound(slug);
    }

    return json({
      category,
      articles: articles.articlesConnection,
      ...aboutSubFormContactUs,
    } as CategoryLocalization);
  };

export const getArticlesByCategory = async ({
  client,
  slug,
  siteName,
  locale,
  sections,
  isDraft,
  pageNumber,
}: CategoryQueryFilter) => {
  const skip = getRecordsToSkip(pageNumber);
  // Query hygraph
  const variables = {
    where: {
      title_not: '-',
      category: {
        slug,
        sites_some: { name: siteName },
        relatedCategorySection: getSectionQuery(sections),
      },
      sites_some: { name: siteName },
    },
    locale: getHygraphLocales(locale),
    localesWithDefault: [locale, Locale.Default],
    stage: isDraft ? Stage.Draft : Stage.Published,
    first: pageConfig.pageSize,
    skip,
  };

  const { data, error } =
    await client.query<GetArticlesByCategoryPaginationQuery>({
      query: GetArticlesByCategoryPaginationDocument,
      variables,
      fetchPolicy: 'network-only',
    });

  if (error) {
    throw internalError(error);
  }

  return data;
};

export const getCategoryWithLatestArticlesForChildCategoies = async ({
  client,
  slug,
  siteName,
  locale,
  sections,
  isDraft,
}: CategoryQueryFilter): Promise<Category | undefined> => {
  const relatedCategorySection = getSectionQuery(sections);
  // Query hygraph
  const variables = {
    where: {
      slug,
      sites_some: { name: siteName },
      relatedCategorySection,
    },
    site: siteName,
    sectionWhere: relatedCategorySection,
    locale: getHygraphLocales(locale),
    localesWithDefault: [locale, Locale.Default],
    stage: isDraft ? Stage.Draft : Stage.Published,
  };

  const { data, error } =
    await client.query<GetCategoriesWithLatestArticlesQuery>({
      query: GetCategoriesWithLatestArticlesDocument,
      variables,
      fetchPolicy: 'network-only',
    });

  if (error) {
    throw internalError(error);
  }

  if (data.categories.length > 0 && data.categories[0].localizations.length > 0)
    return (
      filterObjectsByContentLocaleOrDefault(
        data.categories[0].localizations,
        locale,
      ) as Category[]
    )[0];
  return;
};

export const getParentSectionNames = async ({
  client,
  sectionIds,
}: sectionsQuery): Promise<sectionData[] | undefined> => {
  const variables = {
    where: {
      id_in: sectionIds,
    },
  };

  const { data, error } = await client.query<{ sections: sectionData[] }>({
    query: GetParentSectionDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  if (error) {
    throw internalError(error);
  }

  return data.sections;
};
// Get Contact US and Subscription Form for under "about" section
// Currently its being used for "about/news" section
// http://www.seek.com.au.local:8000/about/news/category/seek-news
// http://www.seek.com.au.local:8000/about/news/article/employment-dashboard-dec-2022

export const getAboutSubForm_ContactUs = async (
  section: string,
  queryProps: QueryProps,
) => {
  if (section === 'about') {
    const formData = await getSubscriptionForm(queryProps);

    const contactUs = await getContactUs(queryProps);
    return {
      formData,
      contactUs,
    };
  }
  return {
    formData: null,
    contactUs: null,
  };
};

export const getContactUs = async ({
  hygraphLocale,
  hygraphSite,
  isDraft,
  client,
}: QueryProps) => {
  // Hardcoded slug for contact us, need to create it in Hygraph BannerItem Components
  const bannerItemVariables = {
    where: {
      slug: 'contactus',
      sites_some: { name: hygraphSite },
    },
    first: 1,
    stage: isDraft ? Stage.Draft : Stage.Published,
    locale: [hygraphLocale, Locale.Default],
  };
  // TODO: log the error inside DataDog?
  const { data: contactUsData } = await client.query<GetBannerItemQuery>({
    query: GetBannerItemDocument,
    variables: bannerItemVariables,
    fetchPolicy: 'network-only',
  });
  // Add the section filter to render it for about/news
  if (Boolean(contactUsData.bannerItems)) {
    return contactUsData.bannerItems[0]; // Return the first item
  }
  return null;
};

export const getSubscriptionForm = async ({
  hygraphLocale,
  hygraphSite,
  isDraft,
  client,
}: QueryProps) => {
  // Hardcoded slug for subscribe form, need to create it in Hygraph Form Components
  const variables = {
    where: {
      slug: 'subscribe',
      sites_some: { name: hygraphSite },
    },
    first: 1,
    stage: isDraft ? Stage.Draft : Stage.Published,
    locale: getHygraphLocales(hygraphLocale),
  };

  const { data, error } = await client.query<GetFormDataQuery>({
    query: GetFormDataDocument,
    variables,
    fetchPolicy: 'network-only',
  });

  if (error) {
    throw internalError(error);
  }
  if (Boolean(data.forms)) {
    return data.forms[0]; // Return the first item
  }
  return;
};

const loadLinkParentSectionData = async ({
  client,
  pageData,
}: LoadLinkDataProps) => {
  const sectionIds = iterateContainersData(true, pageData);
  if (sectionIds) {
    const sectionList = await getParentSectionNames({ client, sectionIds });
    iterateContainersData(false, pageData, sectionList);
  }
};

const iterateContainersData = (
  fetchData: boolean,
  pageData: GetPagesQuery['pages'][0],
  sectionList?: sectionData[],
) => {
  let sectionIds;
  for (const container of pageData.containers) {
    for (const block of container.blocks) {
      for (const item of block.items) {
        if (item.__typename === 'CActionGroup') {
          for (const action of item.actions) {
            if (action.link) {
              let sectionId;
              if (action.link.to?.__typename === 'Article') {
                sectionId = action.link.to.category?.relatedCategorySection?.id;
              } else if (action.link.to?.__typename === 'Page') {
                sectionId = action.link.to.relatedPageSection?.id;
              } else if (action.link.to?.__typename === 'Category') {
                sectionId = action.link.to.relatedCategorySection?.id;
              }
              if (sectionId) {
                if (fetchData) {
                  if (!sectionIds) sectionIds = [sectionId];
                  else sectionIds.push(sectionId);
                } else {
                  const sectionData = sectionList?.find(
                    (section) => sectionId === section.id,
                  );
                  action.link = updateLinkData(action.link, sectionData);
                }
              }
            }
          }
        }
      }
    }
  }
  return sectionIds;
};

const updateLinkData = (link: LinkFragment, sectionData?: sectionData) => {
  if (link.to && sectionData) {
    return {
      ...link,
      to: {
        ...link.to,
        parentSection: {
          sectionName: sectionData?.parentSection?.sectionName,
        },
      } as unknown as LinkToFragment,
    };
  }
};
