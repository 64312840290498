import { Path, StyleSheet, Svg, View } from '@react-pdf/renderer';
import { PdfHeadingH1, PdfHeadingH4 } from '../components/text';
import { talPdfConfig } from './config';
import translations from '../../../.vocab';
import { useTranslations } from '@vocab/react';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#122F83',

    position: 'relative',
    display: 'flex',
  },
  innerContainer: {
    margin: '0 60px',
    padding: '48px 0',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  headingContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  heading: {
    color: '#fff',
  },
  subHeading: {
    color: '#fff',
  },
  donutShape: {
    position: 'absolute',
    top: '8%',
    right: '4%',
  },
  semiDonutShape: {
    position: 'absolute',
    bottom: 0,
    right: '10%',
    height: 100,
  },
});

const PdfSeekDonutShape = ({ color }: { color: string }) => (
  <Svg width="80" height="80" viewBox="0 0 80 80" fill="none">
    <Path
      fillRule="evenodd"
      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM40 53.3333C47.3638 53.3333 53.3333 47.3638 53.3333 40C53.3333 32.6362 47.3638 26.6667 40 26.6667C32.6362 26.6667 26.6667 32.6362 26.6667 40C26.6667 47.3638 32.6362 53.3333 40 53.3333Z"
      // fill="#1E47A9"
      fill={color}
    />
  </Svg>
);

const PdfSeekSemiDonutShape = ({ color }: { color: string }) => (
  <Svg width="200" height="100" viewBox="0 0 200 100" fill="none">
    <Path
      fillRule="evenodd"
      d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200ZM100 133.333C118.409 133.333 133.333 118.409 133.333 100C133.333 81.5905 118.409 66.6667 100 66.6667C81.5905 66.6667 66.6667 81.5905 66.6667 100C66.6667 118.409 81.5905 133.333 100 133.333Z"
      fill={color}
    />
  </Svg>
);

const StyledSeekDonutShape = () => (
  <View style={styles.donutShape}>
    <PdfSeekDonutShape color="#1E47A9" />
  </View>
);

const StyledSemiDonutShape = () => (
  <View style={styles.semiDonutShape}>
    <PdfSeekSemiDonutShape color={'#1E47A9'} />
  </View>
);

export const TalPdfHeader = () => {
  const { t } = useTranslations(translations);
  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.headingContainer}>
          <PdfHeadingH1 style={styles.heading}>
            {talPdfConfig.header}
          </PdfHeadingH1>
          <PdfHeadingH4 style={styles.subHeading}>
            {t('Uncover the drivers that attract candidates to a role.')}
          </PdfHeadingH4>
        </View>
        <StyledSeekDonutShape />
        <StyledSemiDonutShape />
      </View>
    </View>
  );
};
