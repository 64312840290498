import { Link, StyleSheet, Text, type Styles } from '@react-pdf/renderer';
import type { FC, PropsWithChildren } from 'react';

const reset = {
  margin: 0,
  padding: 0,
  fontSize: '16px',
  lineHeight: 1.53,
  color: '#2E3849',
};
const styles = StyleSheet.create({
  headingH1: {
    ...reset,
    fontSize: '36px',
    lineHeight: 1.17,
    fontWeight: 700,
    color: '#2E3849',
  },
  headingH2: {
    ...reset,
    fontSize: '30px',
    lineHeight: 1.22,
    fontWeight: 700,
    color: '#2E3849',
  },
  headingH3: {
    ...reset,
    fontSize: '20px',
    lineHeight: 1.23,
    fontWeight: 700,
    color: '#2E3849',
  },
  headingH4: {
    ...reset,
    fontSize: '18px',
    lineHeight: 1.51,
    fontWeight: 400,
    color: '#2E3849',
  },
  headingH5: {
    ...reset,
    fontSize: '16px',
    lineHeight: 1.53,
    fontWeight: 700,
    color: '#2E3849',
  },
  textSmall: {
    ...reset,
    fontSize: '12px',
    lineHeight: 1.53,
    fontWeight: 'normal',
    color: '#5A6881',
  },
  text: {
    ...reset,
    fontSize: '16px',
    lineHeight: 1.53,
    fontWeight: 'normal',
    color: '#2E3849',
  },
  textBold: {
    ...reset,
    fontWeight: 700,
  },
  link: {
    ...reset,
    fontWeight: 'bold',
    color: '#2E3849',
    // @ts-ignore
    display: 'inline',
  },
});

type PdfTextProps = PropsWithChildren<Text['props']>;

export const PdfText: FC<PdfTextProps> = ({ children, ...props }) => (
  <Text {...props} style={{ ...styles.text, ...(props.style as Styles) }}>
    {children}
  </Text>
);

export const PdfTextSmall: FC<PdfTextProps> = ({ children, ...props }) => (
  <Text {...props} style={{ ...styles.textSmall, ...(props.style as Styles) }}>
    {children}
  </Text>
);

export const PdfTextBold: FC<PdfTextProps> = ({ children, ...props }) => (
  <Text {...props} style={{ ...styles.textBold, ...(props.style as Styles) }}>
    {children}
  </Text>
);

type PdfHeadingProps = PropsWithChildren<Text['props']>;

export const PdfHeadingH1: FC<PdfHeadingProps> = ({ children, ...props }) => (
  <Text {...props} style={{ ...styles.headingH1, ...(props.style as Styles) }}>
    {children}
  </Text>
);

export const PdfHeadingH2: FC<PdfHeadingProps> = ({ children, ...props }) => (
  <Text {...props} style={{ ...styles.headingH2, ...(props.style as Styles) }}>
    {children}
  </Text>
);

export const PdfHeadingH3: FC<PdfHeadingProps> = ({ children, ...props }) => (
  <Text {...props} style={{ ...styles.headingH3, ...(props.style as Styles) }}>
    {children}
  </Text>
);

export const PdfHeadingH4: FC<PdfHeadingProps> = ({ children, ...props }) => (
  <Text {...props} style={{ ...styles.headingH4, ...(props.style as Styles) }}>
    {children}
  </Text>
);
export const PdfHeadingH5: FC<PdfHeadingProps> = ({ children, ...props }) => (
  <Text {...props} style={{ ...styles.headingH5, ...(props.style as Styles) }}>
    {children}
  </Text>
);

type PdfLinkProps = PropsWithChildren<Link['props']>;

export const PdfTextLink: FC<PdfLinkProps> = ({ children, ...props }) => (
  <Link {...props} style={styles.link}>
    {children}
  </Link>
);
