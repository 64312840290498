import { Dropdown, Stack } from 'braid-design-system';
import { MultipleSelectDropdown } from '../../MultipleSelectDropdown/MultipleSelectDropdown';
import type { UseQueryBannerFormFieldsTypes } from '../hooks/useQueryFilterBannerFormFields';
import type { DriverList } from './QueryFilter';
import type { UseFieldsTypes } from './useFields';
import { useDatalab } from '../DatalabProvider';
import { datalabHelper } from '../datalabHelper';

interface FilterProp {
  filterLabel?: string;
  filterPlaceholder?: string;
}

interface DriverFilterSelectorProp extends FilterProp {
  optionFilter: FilterProp;
}

export interface DriverOption {
  name: string;
}
export interface Driver {
  name: string;
  options: DriverOption[];
}

interface DriverFilterSelectorProps {
  id: string;
  driver:
    | UseFieldsTypes['primaryDriverSelect']
    | UseFieldsTypes['secondaryDriverSelect']
    | UseQueryBannerFormFieldsTypes['primaryDriverSelect'];
  driverList: DriverList;
  driverOptionsList?: { value: string; label: string }[];
  driverOptionsSelect:
    | UseFieldsTypes['primaryDriverOptionsSelect']
    | UseFieldsTypes['secondaryDriverOptionsSelect']
    | UseQueryBannerFormFieldsTypes['primaryDriverOptionsSelect'];
  filterProp: DriverFilterSelectorProp;
  driverDisabled: boolean;
  driverOptionDisabled?: boolean;
  isDark?: boolean;
}

export const DriverFilterSelector = ({
  id,
  filterProp,
  driverList,
  driverDisabled,
  driverOptionDisabled,
  driverOptionsList,
  driverOptionsSelect,
  driver,
  isDark = false,
}: DriverFilterSelectorProps) => {
  const { dataTranslations } = useDatalab();
  const multiSelectOptionValue = driverOptionsSelect.value.map((item) => ({
    value: item,
    label: datalabHelper.getTranslationFor(dataTranslations, item),
  }));
  const translatedDriverOptionsList = driverOptionsList?.map((option) => ({
    value: option.value,
    label: datalabHelper.getTranslationFor(dataTranslations, option.label),
  }));

  // Only show placeholder when first item in list is not 'null'
  // 'null' value will make the first drop down as selectable placeholder
  const placeholder =
    driverList.drivers[0].name !== 'null' ? filterProp.filterPlaceholder : '';

  const getFilterLabelFrom = (
    name: string,
    label: string = 'Select a filter',
  ) => (name !== 'null' ? name : label);

  return (
    <Stack space="large">
      <Dropdown
        id={id}
        label={filterProp.filterLabel}
        onChange={(newValue) => {
          driver.onChange(newValue.currentTarget.value);
          driverOptionsSelect.onChange([]);
        }}
        value={driverDisabled ? '' : driver.value}
        disabled={driverDisabled}
        tone={!driver.valid ? 'critical' : 'neutral'}
        placeholder={placeholder}
        message={!driver.valid && driver.errorMessage}
      >
        {driverList.drivers.map((d) => {
          const label = getFilterLabelFrom(
            d.name,
            filterProp.filterPlaceholder,
          );

          const translatedLabel = datalabHelper.getTranslationFor(
            dataTranslations,
            label,
          );
          return (
            <option key={d.name} value={d.name} label={translatedLabel}>
              {translatedLabel}
            </option>
          );
        })}
      </Dropdown>

      <MultipleSelectDropdown
        options={translatedDriverOptionsList}
        onChange={(newValue) =>
          driverOptionsSelect.onChange(newValue.map((item) => item.value))
        }
        canSelect={multiSelectOptionValue.length < 2}
        value={multiSelectOptionValue}
        id={driver.value}
        title={filterProp.optionFilter.filterLabel}
        placeholder={filterProp.optionFilter.filterPlaceholder}
        isDisabled={driverOptionDisabled}
        message={driverOptionsSelect?.errorMessage}
        isValid={driverOptionsSelect.valid}
        isDark={isDark}
      />
    </Stack>
  );
};
