import React from 'react';
import {
  Box,
  Stack,
  Link,
  Text,
  Spread,
  IconChevron,
  ButtonIcon,
  Divider,
} from 'braid-design-system';

import type { LinkProps } from '../types';
import { LanguageToggle } from './LanguageToggle';
import { useCustomHeaderContext } from '../CustomHeaderProvider';
import { getUrlPath } from '../helpers';

export const MobileLink = ({
  text,
  url,
  subLinks,
  activeRoute,
}: {
  text: string;
  url?: string;
  subLinks: LinkProps[];
  activeRoute?: string;
}) => {
  const [open, toggleOpen] = React.useState(false);
  const hasSubLinks = subLinks.length > 0;

  const renderSubLinks = React.useMemo(
    () => (
      <Box paddingBottom="small">
        {subLinks.map(({ text: childText, url: childUrl }, j: number) => {
          const isActive = activeRoute === getUrlPath(childUrl);
          if (isActive) {
            toggleOpen(true);
          }

          return (
            <Box paddingY="none" paddingX="medium" key={j} width="full">
              <Text size="small" weight={isActive ? 'strong' : 'regular'}>
                <Link href={childUrl}>
                  <Box paddingY="medium">{childText}</Box>
                </Link>
              </Text>
            </Box>
          );
        })}
      </Box>
    ),
    [subLinks, activeRoute],
  );

  return (
    <Stack space="none">
      <Box paddingTop="xxsmall" paddingBottom={open ? 'none' : 'xxsmall'}>
        <Spread space="small" alignY="center">
          <Box
            paddingX="small"
            width="full"
            onClick={() => (!url ? toggleOpen(!open) : null)}
          >
            <Text
              size="small"
              weight={
                activeRoute && activeRoute === getUrlPath(url)
                  ? 'strong'
                  : 'regular'
              }
            >
              <Link href={url || ''}>
                <Box paddingY="medium">{text}</Box>
              </Link>
            </Text>
          </Box>
          {hasSubLinks && (
            <Box paddingRight="small">
              <ButtonIcon
                id="expand"
                label="expand-group"
                variant="transparent"
                icon={<IconChevron direction={open ? 'up' : 'down'} />}
                onClick={() => toggleOpen(!open)}
              />
            </Box>
          )}
        </Spread>
      </Box>
      {hasSubLinks && open && renderSubLinks}
    </Stack>
  );
};

interface Props {
  open: boolean;
}

export const MobileMenu = ({ open }: Props) => {
  const { activeRoute, links, enableLanguageToggle } = useCustomHeaderContext();
  if (!open) return null;

  return (
    <Box background="surface">
      <Stack space="none">
        {links &&
          links.map(({ text, url, subLinks }, i: number) => (
            <>
              <Divider />
              <MobileLink
                text={text}
                url={url}
                subLinks={subLinks}
                key={i}
                activeRoute={activeRoute}
              />
            </>
          ))}
        {enableLanguageToggle ? (
          <>
            <Divider />
            <LanguageToggle mobile={true} />
          </>
        ) : null}
      </Stack>
      <Divider />
    </Box>
  );
};
