import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { useTranslations } from '@vocab/react';
import translations from '../../../.vocab';
import {
  PdfHeadingH2,
  PdfHeadingH5,
  PdfText,
  PdfTextBold,
  PdfTextSmall,
} from '../components/text';
const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flexDirection: 'column',
    gap: '48px',
    display: 'flex',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  summaryItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  summaryGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '64px',
  },
  summaryGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  summaryNumber: {
    width: '48px',

    flexShrink: 0,
  },
  summaryNumberItem: {
    paddingTop: '2px',
    color: '#ffffff',
  },
  summaryBulletNumberContainer: {
    flexShrink: 0,
    backgroundColor: '#1E47A9',
    borderRadius: '100%',
    width: '48px',
    height: '48px',
  },
  summaryBulletNumber: {
    flexShrink: 0,
    backgroundColor: '#1E47A9',
    padding: '15px 12px 12px 12px',
    borderRadius: '100%',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '48px',
    height: '48px',
    fontSize: '16px',
    lineHeight: 1,
  },
  summaryItemContent: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
});

export type PdfDataSummary = {
  driverName: string;
  category: {
    name: string;
    mustHave: string;
  }[];
};

export const TalPdfSummary = ({ data }: { data: PdfDataSummary[] }) => {
  const { t } = useTranslations(translations);

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <PdfHeadingH2>{t('Data snapshot')}</PdfHeadingH2>
        <View style={styles.summaryGroupContainer}>
          {data.map((attraction) => (
            <View key={attraction.driverName} style={styles.summaryGroup} break>
              <PdfText>
                {t('Top 3 drivers of attraction for')}{' '}
                <PdfTextBold>
                  {data.length === 1 && !attraction.driverName
                    ? t('All respondents')
                    : attraction.driverName}
                </PdfTextBold>
              </PdfText>

              <View style={styles.summaryContainer}>
                {attraction.category.map((category, categoryIndex) => (
                  <View key={category.name} style={styles.summaryItem}>
                    <View>
                      <Text style={styles.summaryBulletNumber}>
                        {categoryIndex + 1}
                      </Text>
                    </View>

                    <View style={styles.summaryItemContent}>
                      <PdfHeadingH5>{category.name}</PdfHeadingH5>
                      <PdfText
                        wrap={true}
                        style={{ display: 'flex', maxWidth: '590px' }}
                      >
                        {t(
                          'Within this, secondary driver is the number one must-have for candidates.',
                          {
                            driver: category.mustHave,
                          },
                        )}
                      </PdfText>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
      </View>
      <View>
        <PdfTextSmall>
          {t(
            "What's a must-have? An essential factor candidates expect a job to have.",
          )}
        </PdfTextSmall>
      </View>
    </View>
  );
};
