import React from 'react';
import { GalleryCarousel } from './components/GalleryCarousel/GalleryCarousel';
import type {
  AssetFragment,
  BorderRadius,
  DefaultImageUrlTransformationFragment,
  TileFragment,
} from '@seek/cmsu-cms-connect';

export type AssetType = AssetFragment & DefaultImageUrlTransformationFragment;

interface GalleryProps {
  assets: AssetType[];
  columns?: TileFragment['columns'];
  space?: TileFragment['space'];
  imageBorderRadius?: BorderRadius;
}

export const Gallery = ({
  assets,
  columns,
  space,
  imageBorderRadius,
}: GalleryProps) => (
  <GalleryCarousel
    assets={assets}
    columns={columns}
    space={space}
    imageBorderRadius={imageBorderRadius}
  />
);
