import {
  BorderRadius,
  CTextSize,
  CustomFontSize,
  type BannerInlineFragment,
  type ParagraphFragment,
} from '@seek/cmsu-cms-connect';
import {
  Badge,
  Box,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from 'braid-design-system';
import { ActionGroup } from '../../ActionGroup/ActionGroup';
import { Asset, AssetType } from '../../Asset/Asset';
import { Heading } from '../../Heading/Heading';
import { Paragraph } from '../../Paragraph/Paragraph';
import type { DatalabSurvey } from '../../Talent-Attraction-Lab/datalabHelper';
import { BannerDivider } from '../BannerDivider';
import { InlineBannerCustomComponent } from './InlineBannerCustomComponent';
import { Video } from '../../Video/Video';
import { getVideoUrl } from '../../../helpers/getVideoUrl';

type Props = {
  datalabSurvey?: DatalabSurvey; // To set as DatalabSurvey Type
  bannerData?: Pick<
    BannerInlineFragment,
    | 'reverse'
    | 'reverseContent'
    | 'image'
    | 'video'
    | 'heading'
    | 'paragraph'
    | 'actionGroup'
    | 'inlineDivider'
    | 'badge'
    | 'imageHiddenBelow'
    | 'imageBorderRadius'
    | 'customComponentRenderer' // TODO: This value shouldnt be empty, we should render the custom component based on availability of this value
  >;
};

const getDesktopContentReversal = (
  reverseContent: BannerInlineFragment['reverseContent'],
) => {
  switch (reverseContent) {
    case 'both':
      return false;
    case 'mobile':
      return true;
    case 'desktop':
      return true;
    default:
      return false;
  }
};

// Check if the `image` ratio is almost square, or taller than wide
const checkImageRatio = (image: BannerInlineFragment['image']) => {
  let ratio = 1.5;
  if (image?.width && image?.height) {
    ratio = image?.width / image?.height;
  }

  return ratio < 1.15
    ? AssetType.INLINE_BANNER_PORTRAIT
    : AssetType.INLINE_BANNER;
};

export const InlineBanner = ({ bannerData, datalabSurvey }: Props) => {
  /**
   * There are some styling issue when we directly use the <Hidden> component
   * if we wrap the image column with Hidden, it will cause the image size shrink
   * if we wrap the Asset component with Hidden in the Column, it will cause there is
   * unused Column in mobile view and content not vertical center.
   */
  const responsiveValue = useResponsiveValue();
  const isDeskTop = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: false,
    wide: true,
  });
  const isTablet = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: true,
    wide: true,
  });

  const hiddenBelow =
    bannerData?.imageHiddenBelow === 'none'
      ? 'mobile'
      : bannerData?.imageHiddenBelow ?? 'mobile';

  const shouldRenderImage = (hidden: string) => {
    if (!bannerData?.image) return null;

    let shouldRender = false;
    switch (hidden) {
      case 'mobile':
        shouldRender = true;
        break;
      case 'tablet':
        shouldRender = (isDeskTop || isTablet) ?? false;
        break;
      case 'desktop':
        shouldRender = isDeskTop || false;
        break;
      default:
        break;
    }
    return shouldRender ? (
      <Column width="1/2" key="asset">
        <Box
          borderRadius={bannerData?.imageBorderRadius || 'none'}
          style={{ overflow: 'hidden' }}
        >
          <Asset
            assetType={checkImageRatio(bannerData?.image)}
            {...bannerData?.image}
          />
        </Box>
      </Column>
    ) : null;
  };

  // Extra spacing for custom-large headings that have a paragraph (more than a single line) underneath
  const getLargeHeadingExtraSpacing = (
    headingSize: CustomFontSize | null | undefined,
    paragraph: ParagraphFragment | null | undefined,
  ) => {
    if (!headingSize || !paragraph) return 'none';

    const { Paragraph_text } = paragraph;
    const paragraphText = Paragraph_text?.raw?.children[0]?.children[0]?.text;

    if (paragraphText && paragraphText.length < 80) return 'none';

    switch (headingSize) {
      case CustomFontSize.Xlarge:
      case CustomFontSize.Xxlarge:
      case CustomFontSize.Xxxlarge:
        return 'large';
      case CustomFontSize.Large:
        return 'medium';
      case CustomFontSize.Standard:
        return 'small';
      default:
        return 'none';
    }
  };

  const content = (
    <Column key="content">
      <Stack space="gutter">
        {bannerData?.badge && (
          <Badge {...bannerData?.badge} weight="strong">
            {bannerData?.badge.text}
          </Badge>
        )}
        {bannerData?.heading && (
          <Box
            paddingBottom={getLargeHeadingExtraSpacing(
              bannerData?.heading.customHeadingSize,
              bannerData?.paragraph,
            )}
          >
            <Heading {...bannerData?.heading} />
          </Box>
        )}
        {bannerData?.paragraph && (
          <Paragraph
            content={bannerData?.paragraph.Paragraph_text?.raw}
            {...bannerData?.paragraph}
            size={bannerData?.paragraph.size || CTextSize.Large}
          />
        )}
        {bannerData?.actionGroup && (
          <Inline space="none">
            <ActionGroup
              {...bannerData?.actionGroup}
              size={bannerData?.actionGroup.actionGroupSize}
            />
          </Inline>
        )}
        {bannerData?.inlineDivider && (
          <BannerDivider
            image={bannerData?.inlineDivider.image}
            actions={bannerData?.inlineDivider}
          />
        )}
        {bannerData?.customComponentRenderer && (
          <InlineBannerCustomComponent
            componentType={bannerData?.customComponentRenderer}
            datalabSurvey={datalabSurvey}
          />
        )}
      </Stack>
    </Column>
  );

  const getAsset = (): JSX.Element | null => {
    if (bannerData?.video) {
      const url = getVideoUrl(bannerData?.video);
      return url ? (
        <Column width="1/2" key="asset">
          <Box paddingY="gutter">
            <Video url={url} size="small" borderRadius={BorderRadius.Xlarge} />
          </Box>
        </Column>
      ) : null;
    }
    if (bannerData?.image) {
      return shouldRenderImage(hiddenBelow);
    }

    return null;
  };

  // Given {image} is content #1, and {content} is content #2,
  // we need to set the order of the content based on the reverseContent value
  const getContentOrder = () => {
    const firstCol = getAsset();
    const secondCol = content;

    switch (bannerData?.reverseContent) {
      case 'both':
        return [secondCol, firstCol];
      case 'mobile':
        return [secondCol, firstCol];
      case 'desktop':
        return [firstCol, secondCol];
      default:
        return [firstCol, secondCol];
    }
  };

  /* NOV'24: Provide a fallback to the 'old' layout so the `reverse` prop
  /* still functions as expected. The reverse props is now deprecated and
  /* and accessible only via API.
  */
  return (
    <Box data={{ testid: 'inlineBannerTestId' }}>
      {bannerData?.reverseContent ? (
        <Columns
          space={{ mobile: 'gutter', tablet: 'xlarge' }}
          alignY="center"
          collapseBelow="tablet"
          reverse={getDesktopContentReversal(bannerData?.reverseContent)}
        >
          {getContentOrder()}
        </Columns>
      ) : (
        <Columns
          space={{ mobile: 'gutter', tablet: 'xlarge' }}
          alignY="center"
          collapseBelow="tablet"
          reverse={bannerData?.reverse || false}
        >
          {getAsset()}
          {content}
        </Columns>
      )}
    </Box>
  );
};
