import type {
  BoxFragment,
  ImageBlockFragment,
  ImageBlockGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Box } from 'braid-design-system';
import React from 'react';
import { InlineImageBlock } from './InlineImageBlock';
import { RegularImageBlock } from './RegularImageBlock';

type Props = Pick<
  ImageBlockFragment,
  | 'heading'
  | 'image'
  | 'paragraph'
  | 'cButtonLink'
  | 'cTextLink'
  | 'dialogButton'
> &
  Pick<
    ImageBlockGroupFragment,
    'inlineBlockItem' | 'blockBackground' | 'imageAlignment' | 'stackBelow'
  > & {
    inlineTextJustify?: BoxFragment['justifyContent'];
  };

const ImageBlock = ({
  image,
  heading,
  paragraph,
  inlineBlockItem,
  blockBackground,
  imageAlignment,
  cButtonLink,
  cTextLink,
  dialogButton,
  stackBelow,
  inlineTextJustify,
}: Props) => (
  <Box data={{ testid: 'ImageBlockTestId' }} height="full">
    {inlineBlockItem ? (
      <InlineImageBlock
        imageAlignment={imageAlignment}
        heading={heading}
        image={image}
        paragraph={paragraph}
        blockBackground={blockBackground}
        cButtonLink={cButtonLink}
        cTextLink={cTextLink}
        dialogButton={dialogButton}
        inlineTextJustify={inlineTextJustify}
        stackBelow={stackBelow}
      />
    ) : (
      <RegularImageBlock
        heading={heading}
        image={image}
        paragraph={paragraph}
        blockBackground={blockBackground}
        cButtonLink={cButtonLink}
        cTextLink={cTextLink}
        dialogButton={dialogButton}
      />
    )}
  </Box>
);
export { ImageBlock };
