import type { VideoFragment } from '@seek/cmsu-cms-connect';
import { VideoSrcType } from '../modules/Video/Video';

export const getVideoUrl = (video: VideoFragment) => {
  if (!video) {
    return undefined;
  }

  if (video.source?.__typename === VideoSrcType.Brightcove) {
    return video.source.videoUrl;
  }

  if (video.source?.__typename === VideoSrcType.YouTube) {
    return `https://www.youtube.com/embed/${video?.source?.videoId}`;
  }
};
