import { QueryFilterBannerForm } from '@seek/cmsu-components/src/modules/Talent-Attraction-Lab/components/QueryFilterBanner/QueryFilterBannerForm';
import type { DatalabSurvey } from '@seek/cmsu-components/src/modules/Talent-Attraction-Lab/datalabHelper';
import { useTranslations } from '@vocab/react';
import { Box, ContentBlock, Heading, Stack } from 'braid-design-system';
import translations from './.vocab';

export const EmbededQueryFilterTAL = ({
  datalabSurvey,
}: {
  datalabSurvey?: DatalabSurvey;
}) => {
  const { t } = useTranslations(translations);
  return (
    <ContentBlock width="large">
      <Box
        background="customDark"
        padding={'large'}
        borderRadius={'large'}
        style={{
          background: '#122F83', // seekBlueLight800
        }}
      >
        <Stack space="large">
          <Heading level="3">
            {t(
              'Is Salary/Compensation the number 1 driver of your industry too?',
            )}
          </Heading>
          <QueryFilterBannerForm datalabSurvey={datalabSurvey} />
        </Stack>
      </Box>
    </ContentBlock>
  );
};
