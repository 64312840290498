import {
  Box,
  Column,
  Columns,
  Hidden,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import React, { type MouseEvent, useEffect, useState } from 'react';
import { carousel, carouselItem } from './GalleryCarousel.css';
import {
  BackgroundColor,
  type BorderRadius,
  type TileFragment,
} from '@seek/cmsu-cms-connect';
import { GalleryItem } from '../GalleryItem/GalleryItem';
import { NavButton } from '../NavButton/NavButton';
import type { AssetType } from '../../Gallery';
import { vars } from 'braid-design-system/css';
import { useScreenSize } from '../../../../hooks/useScreenSize';

export const GalleryCarousel = ({
  assets,
  columns,
  space,
  imageBorderRadius,
}: {
  assets: AssetType[];
  columns?: TileFragment['columns'];
  space?: TileFragment['space'];
  imageBorderRadius?: BorderRadius;
}) => {
  const { isMobile } = useScreenSize();
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [displayedAssets, setDisplayedAssets] = useState<AssetType[] | []>([]);
  const lastItemIndex = React.useMemo(() => assets.length - 1, [assets]);

  let MAX_ITEMS_SHOWN = columns || 3;

  if (isMobile) {
    MAX_ITEMS_SHOWN = 1;
  }

  useEffect(() => {
    // Update the asset list based on the current index,
    // looping back to the start if necessary to create
    // a continuous loop of assets
    const updateAssetList = (index: number) => {
      const nextIndex = index - assets.length + MAX_ITEMS_SHOWN;

      const arr = [
        ...assets.slice(index, index + MAX_ITEMS_SHOWN),
        ...assets.slice(0, nextIndex >= 0 ? nextIndex : 0),
      ];
      setDisplayedAssets(arr);
    };

    updateAssetList(currentItemIndex);
  }, [currentItemIndex, assets, MAX_ITEMS_SHOWN]);

  const moveItemsLeft = (event: MouseEvent) => {
    event.preventDefault();
    if (currentItemIndex === 0) {
      setCurrentItemIndex(lastItemIndex);
    } else {
      setCurrentItemIndex((prevState) => prevState - 1);
    }
  };

  const moveItemsRight = (event: MouseEvent) => {
    event.preventDefault();
    if (currentItemIndex === lastItemIndex) {
      setCurrentItemIndex(0);
    } else {
      setCurrentItemIndex((prevState) => prevState + 1);
    }
  };

  return (
    <Stack space="large">
      <Columns space={'small'} align="center" alignY="center">
        {assets.length <= MAX_ITEMS_SHOWN ? null : (
          <Column width="content" hideBelow="desktop">
            <NavButton
              direction="left"
              onClick={moveItemsLeft}
              tone={BackgroundColor.BrandAccent}
            />
          </Column>
        )}
        <Column>
          <Box className={carousel}>
            {displayedAssets.map((asset) => (
              <Box
                key={asset.id}
                position="relative"
                className={carouselItem}
                height="full"
                style={{
                  width: `calc(100% / ${MAX_ITEMS_SHOWN} - ${
                    space || vars.space.medium
                  })`,
                }}
              >
                <GalleryItem url={asset.url} borderRadius={imageBorderRadius} />
              </Box>
            ))}
          </Box>
        </Column>
        {assets.length <= MAX_ITEMS_SHOWN ? null : (
          <Column width="content" hideBelow="desktop">
            <NavButton
              direction="right"
              onClick={moveItemsRight}
              tone={BackgroundColor.BrandAccent}
            />
          </Column>
        )}
      </Columns>
      <Hidden above="tablet">
        <Inline space="none" align="center" alignY="center">
          <NavButton
            direction="left"
            onClick={moveItemsLeft}
            tone={BackgroundColor.BrandAccent}
          />
          <Text weight="strong">
            {currentItemIndex + 1} of {assets.length}
          </Text>
          <NavButton
            direction="right"
            onClick={(e) => moveItemsRight(e)}
            tone={BackgroundColor.BrandAccent}
          />
        </Inline>
      </Hidden>
    </Stack>
  );
};
