import { Box } from 'braid-design-system';

import { saraBokeh01 } from './styles.css';

export const SaraBokehDecoration01 = () => (
  <Box>
    <Box data={{ testid: 'sarabokeh01__left' }} className={saraBokeh01.left} />
    <Box
      data={{ testid: 'sarabokeh01__right' }}
      className={saraBokeh01.right}
    />
  </Box>
);
