import type {
  AssetFragment,
  DefaultImageUrlTransformationFragment,
  InlineBannerImageUrlTransformationFragment,
  PrimaryBannerImageUrlTransformationFragment,
} from '@seek/cmsu-cms-connect';
import type { CSSProperties } from 'react';
import {
  type ImageSizeTransformation,
  resizeImageFromHygraph,
} from '../../utils/imageHelper';
import { Box } from 'braid-design-system';

type Props = Pick<AssetFragment, 'alt' | 'mimeType'> &
  Pick<
    | DefaultImageUrlTransformationFragment
    | PrimaryBannerImageUrlTransformationFragment
    | InlineBannerImageUrlTransformationFragment,
    'url'
  > & {
    style?: CSSProperties;
  } & {
    assetType: AssetType;
  };

export enum AssetType {
  DEFAULT_IMAGE = 'DEFAULT_IMAGE',
  PRIMARY_BANNER = 'PRIMARY_BANNER',
  INLINE_BANNER = 'INLINE_BANNER',
  INLINE_BANNER_PORTRAIT = 'INLINE_BANNER_PORTRAIT',
}

const defaultImageSizeTransformation: ImageSizeTransformation = {
  height: 1280,
  width: 1280,
  fit: 'max',
};

const assetImageSizeTransformationByType = new Map<
  AssetType,
  ImageSizeTransformation
>([
  [AssetType.DEFAULT_IMAGE, defaultImageSizeTransformation],
  [AssetType.PRIMARY_BANNER, { height: 900, width: 1600, fit: 'crop' }],
  [AssetType.INLINE_BANNER, { height: 392, width: 616, fit: 'crop' }],
  [AssetType.INLINE_BANNER_PORTRAIT, { height: 616, width: 616, fit: 'max' }],
]);

export const Asset = ({
  url,
  alt,
  style,
  mimeType,
  assetType = AssetType.DEFAULT_IMAGE,
}: Props) => {
  const imageSize =
    assetImageSizeTransformationByType.get(assetType) ||
    defaultImageSizeTransformation;

  return (
    <Box
      component="img"
      data={{ testid: 'assetTestId' }}
      src={
        !mimeType?.includes('svg')
          ? resizeImageFromHygraph({ imageUrl: url, ...imageSize })
          : url
      }
      alt={alt || undefined}
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block',
        ...style,
        ...(assetType === AssetType.INLINE_BANNER && { width: '100%' }),
      }}
    />
  );
};
