
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmB4Lil4Liw4LiE4LmI4Liy4LiV4Lit4Lia4LmB4LiX4LiZ4LmA4Lib4LmH4LiZ4Lib4Lix4LiI4LiI4Lix4Lii4LiX4Li14LmI4Lih4Li14LiE4Lin4Liy4Lih4Liq4Liz4LiE4Lix4LiN4Lit4Lix4LiZ4LiU4Lix4LiaIDEg4LmD4LiZ4Lib4Lij4Liw4LmA4Lig4LiX4LiY4Li44Lij4LiB4Li04LiI4LiC4Lit4LiH4LiE4Li44LiT4Lir4Lij4Li34Lit4LmE4Lih4LmIPyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmB4Lil4Liw4LiE4LmI4Liy4LiV4Lit4Lia4LmB4LiX4LiZ4LmA4Lib4LmH4LiZ4Lib4Lix4LiI4LiI4Lix4Lii4LiX4Li14LmI4Lih4Li14LiE4Lin4Liy4Lih4Liq4Liz4LiE4Lix4LiN4Lit4Lix4LiZ4LiU4Lix4LiaIDEg4LmD4LiZ4Lib4Lij4Liw4LmA4Lig4LiX4LiY4Li44Lij4LiB4Li04LiI4LiC4Lit4LiH4LiE4Li44LiT4Lir4Lij4Li34Lit4LmE4Lih4LmIPyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiQXBha2FoIEdhamkgLyBLb21wZW5zYXNpIGZhdG9yIHBlbmRvcm9uZyBrYW5kaWRhdCBub21vciAxIGRpIGluZHVzdHJpIGFuZGE%2FIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiQXBha2FoIEdhamkgLyBLb21wZW5zYXNpIGZhdG9yIHBlbmRvcm9uZyBrYW5kaWRhdCBub21vciAxIGRpIGluZHVzdHJpIGFuZGE%2FIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiSXMgU2FsYXJ5L0NvbXBlbnNhdGlvbiB0aGUgbnVtYmVyIDEgZHJpdmVyIG9mIHlvdXIgaW5kdXN0cnkgdG9vPyJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmB4Lil4Liw4LiE4LmI4Liy4LiV4Lit4Lia4LmB4LiX4LiZ4LmA4Lib4LmH4LiZ4Lib4Lix4LiI4LiI4Lix4Lii4LiX4Li14LmI4Lih4Li14LiE4Lin4Liy4Lih4Liq4Liz4LiE4Lix4LiN4Lit4Lix4LiZ4LiU4Lix4LiaIDEg4LmD4LiZ4Lib4Lij4Liw4LmA4Lig4LiX4LiY4Li44Lij4LiB4Li04LiI4LiC4Lit4LiH4LiE4Li44LiT4Lir4Lij4Li34Lit4LmE4Lih4LmIPyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmB4Lil4Liw4LiE4LmI4Liy4LiV4Lit4Lia4LmB4LiX4LiZ4LmA4Lib4LmH4LiZ4Lib4Lix4LiI4LiI4Lix4Lii4LiX4Li14LmI4Lih4Li14LiE4Lin4Liy4Lih4Liq4Liz4LiE4Lix4LiN4Lit4Lix4LiZ4LiU4Lix4LiaIDEg4LmD4LiZ4Lib4Lij4Liw4LmA4Lig4LiX4LiY4Li44Lij4LiB4Li04LiI4LiC4Lit4LiH4LiE4Li44LiT4Lir4Lij4Li34Lit4LmE4Lih4LmIPyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiQXBha2FoIEdhamkgLyBLb21wZW5zYXNpIGZhdG9yIHBlbmRvcm9uZyBrYW5kaWRhdCBub21vciAxIGRpIGluZHVzdHJpIGFuZGE%2FIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiQXBha2FoIEdhamkgLyBLb21wZW5zYXNpIGZhdG9yIHBlbmRvcm9uZyBrYW5kaWRhdCBub21vciAxIGRpIGluZHVzdHJpIGFuZGE%2FIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiW8OPxaEgxaDEg8SDxIPGmsSDxIPEg8WZw73DvcO9L8OHw7bDtsO2bcyCxqXhur3hur3hur3guIHguLXguYnFocSDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDhua%2FhuKnhur3hur3hur0g4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmSAxIMaMxZnDrMOsw6zhub3hur3hur3hur3FmSDDtsO2w7bGkiDDvcO9w73DtsO2w7bHmseax5rFmSDDrMOsw6zguIHguLXguYnGjMeax5rHmsWh4bmvxZnDvcO9w70g4bmvw7bDtsO2w7bDtsO2P10ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJcyBTYWxhcnkvQ29tcGVuc2F0aW9uIHRoZSBudW1iZXIgMSBkcml2ZXIgb2YgeW91ciBpbmR1c3RyeSB0b28%2FIjoiW8OPxaEgxaDEg8SDxIPGmsSDxIPEg8WZw73DvcO9L8OHw7bDtsO2bcyCxqXhur3hur3hur3guIHguLXguYnFocSDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDhua%2FhuKnhur3hur3hur0g4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmSAxIMaMxZnDrMOsw6zhub3hur3hur3hur3FmSDDtsO2w7bGkiDDvcO9w73DtsO2w7bHmseax5rFmSDDrMOsw6zguIHguLXguYnGjMeax5rHmsWh4bmvxZnDvcO9w70g4bmvw7bDtsO2w7bDtsO2P10ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    