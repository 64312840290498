import type { BorderRadius } from '@seek/cmsu-cms-connect';
import { Box } from 'braid-design-system';
import { vars } from 'braid-design-system/css';

export const GalleryItem = ({
  url,
  borderRadius,
}: {
  url: string;
  borderRadius?: BorderRadius;
}) => (
  <Box
    role="img"
    borderRadius={borderRadius || 'standard'}
    style={{
      backgroundColor: vars.backgroundColor.neutralSoftHover,
      backgroundImage: `url(${url})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      paddingTop: '100%',
    }}
  />
);
