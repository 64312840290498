import {
  GetFilterFieldsDocument,
  type GetFilterFieldsQuery,
  GetSurveyDocument,
  type GetSurveyQuery,
  GetTranslationsDocument,
  type GetTranslationsQuery,
} from './gql/tal/generated';
import { internalError } from './responses';
import type {
  ApolloClient,
  DocumentNode,
  NormalizedCacheObject,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import {
  datalabHelper,
  type DatalabDriverData,
  type DatalabSurvey,
} from '@seek/cmsu-components/src/modules/Talent-Attraction-Lab/datalabHelper';
import type { Locale } from '@seek/melways-sites';
import { datalabMapper } from '@seek/cmsu-components/src/modules/Talent-Attraction-Lab/datalabMapper';

export interface DatalabLoaderProps {
  clientTAL: ApolloClient<NormalizedCacheObject>;
  locale: Locale;
  request: Request;
  requireSurveyResult?: boolean; // Add control to query for survey results when required
}

export type QueryFilter = {
  driverType: string;
  driverValues: string[];
  drilldownType?: string;
  drilldownValues: string[];
};

const getGraphObjects = async <T,>(
  clientTAL: ApolloClient<NormalizedCacheObject>,
  variables: OperationVariables,
  document: DocumentNode | TypedDocumentNode,
): Promise<T> => {
  const { data, error } = await clientTAL.query<T>({
    query: document,
    variables,
    fetchPolicy: 'cache-first',
  });

  if (error) {
    throw internalError(error);
  }

  return data;
};

const getTranslations = async (
  clientTAL: ApolloClient<NormalizedCacheObject>,
  locale: string,
): Promise<GetTranslationsQuery> =>
  getGraphObjects<GetTranslationsQuery>(
    clientTAL,
    { locale },
    GetTranslationsDocument,
  );

const getQueryFilters = async (
  clientTAL: ApolloClient<NormalizedCacheObject>,
  locale: string,
): Promise<GetFilterFieldsQuery> =>
  getGraphObjects<GetFilterFieldsQuery>(
    clientTAL,
    { locale },
    GetFilterFieldsDocument,
  );

const getSurveyResult = async (
  clientTAL: ApolloClient<NormalizedCacheObject>,
  queryFilter: QueryFilter,
  locale: string,
): Promise<GetSurveyQuery> => {
  const drilldown =
    queryFilter.drilldownType && queryFilter.drilldownValues.length > 0
      ? {
          drilldown: {
            type: queryFilter.drilldownType?.toUpperCase().replaceAll(' ', '_'),
            value: queryFilter.drilldownValues,
          },
        }
      : undefined;

  const variables = {
    locale,
    driver: {
      type: queryFilter.driverType.toUpperCase().replaceAll(' ', '_'),
      value: queryFilter.driverValues,
    },
    ...drilldown,
  };

  const data = getGraphObjects<GetSurveyQuery>(
    clientTAL,
    variables,
    GetSurveyDocument,
  );

  return data;
};

export const loadDatalabData = async ({
  clientTAL,
  locale,
  request,
  requireSurveyResult = true, // Default to true to return all data
}: DatalabLoaderProps): Promise<DatalabSurvey> => {
  const searchParams = new URLSearchParams(
    request.url.substring(request.url.indexOf('?')),
  );
  const queryFilterParams = datalabHelper.getQueryFilterParams(searchParams);

  const surveyFilter: QueryFilter = {
    driverType: queryFilterParams.primaryDriver,
    driverValues: queryFilterParams.primaryDriverOptions || [],
    drilldownType: queryFilterParams.secondaryDriver,
    drilldownValues: queryFilterParams.secondaryDriverOptions || [],
  };

  const queryFilters: GetFilterFieldsQuery = await getQueryFilters(
    clientTAL,
    locale,
  );

  const surveyResults: GetSurveyQuery | null = requireSurveyResult
    ? await getSurveyResult(clientTAL, surveyFilter, locale)
    : null;

  // We can choose to skip fetching translations for sites that does not require translation
  // But, we would then need to maintain the list of sites that requires translations.
  const translations = requiresTranslationsFor(locale)
    ? await getTranslations(clientTAL, locale)
    : null;

  const drivers: DatalabDriverData | null = surveyResults
    ? datalabMapper.mapToDatalabDriverData(surveyResults)
    : null;

  return {
    queryFilters,
    surveyResults,
    drivers,
    queryFilterParams,
    translations: translations?.translations,
    fullURLWithQueryParams: request.url,
  } as DatalabSurvey;
};

const requiresTranslationsFor = (locale: string) =>
  ['id-ID', 'th-TH'].includes(locale);
