import type { RenderParams } from '@seek/forms-ui';
import { useTranslations } from '@vocab/react';
import { Box, Button, Hidden, Inline, Stack } from 'braid-design-system';
import { useMemo, useState } from 'react';
import translations from '../../../.vocab';
import translations_tal from '../.vocab';
import { datalabHelper } from '../datalabHelper';
import { useDriverFilterTranslation } from '../hooks/useDriverFilterTranslation';
import { useGenerateDriverList } from '../hooks/useGenerateDriverList';
import { useQueryFilterDisabledFormState } from '../hooks/useQueryFilterDisabledFormState';
import type { FormValues } from './QueryFilterForm';
import { QueryFilterHeading } from './QueryFilterHeading';
import { useFields } from './useFields';
import { DriverFilterSelector } from './DriverFilterSelector';
import { getCountryName } from '../../../helpers/helper';
import { useResetLoadingOnUnload } from '../hooks/useResetLoadingOnUnload';
import { useTrackLink } from '@seek/cmsu-analytics';
import { useCMSUContext } from '../../../hooks/useCMSUContext';
import { useMelwaysLink } from '@seek/melways-react';
export interface DriverOption {
  name: string;
}
export interface Driver {
  name: string;
  options: DriverOption[];
}
export interface DriverList {
  drivers: Driver[];
}

export type TrackLink =
  | ((moreAdditionalTracking?: { [key: string]: string }) => void)
  | undefined;

interface Props {
  driverList: DriverList;
  handleSubmit: RenderParams<FormValues>['handleSubmit'];
  resetForm: () => void;
  clearField: (fieldName: keyof FormValues) => void;
}

export const QueryFilter = ({
  driverList,
  handleSubmit,
  clearField,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslations(translations);
  const { t: t_tal } = useTranslations(translations_tal);
  const { language, country } = useCMSUContext();
  const melwaysLink = useMelwaysLink();

  const formFields = useFields();
  const {
    primaryDriverSelect,
    primaryDriverOptionsSelect,
    secondaryDriverSelect,
    secondaryDriverOptionsSelect,
  } = formFields;

  const {
    primaryDriverOptionsDisabled,
    secondaryDriverDisabled,
    secondaryDriverOptionsDisabled,
  } = useQueryFilterDisabledFormState({
    primaryDriverOptionsSelect,
    primaryDriverSelect,
    secondaryDriverSelect,
  });

  const {
    primaryDriverList,
    secondaryDriverList,
    primaryOptionsList,
    secondaryOptionsList,
  } = useGenerateDriverList(driverList);
  const { primaryDriverFilter, secondaryDriverFilter } =
    useDriverFilterTranslation();

  // Update driver and option value
  useMemo(() => {
    datalabHelper.updateSecondaryDriverList({
      primaryDrivers: primaryDriverList,
      secondaryDrivers: secondaryDriverList,
      selectedPrimaryDriver: primaryDriverSelect.value,
    });

    // Clear secondary driver and options if primary is same as secondary driver
    if (
      secondaryDriverSelect.value &&
      !secondaryDriverList.drivers.find(
        (driver) => driver.name === secondaryDriverSelect.value,
      )
    ) {
      // secondaryDriverSelect.value = '';
      // secondaryDriverOptionsSelect.value = [];
      clearField('secondaryDriverSelect');
      clearField('secondaryDriverOptionsSelect');
    }
  }, [
    primaryDriverList,
    secondaryDriverList,
    primaryDriverSelect.value,
    secondaryDriverSelect.value,
    clearField,
  ]);

  if (primaryDriverOptionsDisabled) clearField('primaryDriverOptionsSelect');
  if (secondaryDriverDisabled) clearField('secondaryDriverSelect');
  if (secondaryDriverOptionsDisabled)
    clearField('secondaryDriverOptionsSelect');

  const trackLink = useTrackLink('disclosure-label', {
    elementDesign: 'button',
    entryPoint: 'data lab left pane',
  });

  const onSubmit = handleSubmit((formValues: FormValues) => {
    setIsLoading(true);
    window.location.href = melwaysLink({
      path: datalabHelper.querySubmitHandler(formValues),
      language,
      country,
    });
    if (trackLink)
      trackLink({
        elementText: 'Apply Filters',
        elementAction: 'get candidate insight',
        drivers: datalabHelper.getAnalyticsData(
          driverList,
          formValues.primaryDriverOptionsSelect,
          formValues.secondaryDriverOptionsSelect,
        ),
      });
  });

  const handleClearForm = () => {
    clearField('primaryDriverSelect');
    clearField('primaryDriverOptionsSelect');
    clearField('secondaryDriverSelect');
    clearField('secondaryDriverOptionsSelect');
    if (trackLink)
      trackLink({
        elementText: 'Clear All',
        elementAction: 'clear filters',
      });
  };

  useResetLoadingOnUnload(setIsLoading);

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <Box>
        <Stack space="large">
          <Hidden below="desktop">
            <QueryFilterHeading
              title={t('Filters')}
              description={t_tal(
                `Select filters to uncover deeper insights about candidates in country.`,
                { country: country ? getCountryName(country) : '-' },
              )}
            />
          </Hidden>

          {/* PRIMARY */}
          <DriverFilterSelector
            id="PRIMARY_FILTER"
            driver={primaryDriverSelect}
            driverList={primaryDriverList}
            driverOptionsList={primaryOptionsList}
            driverOptionsSelect={primaryDriverOptionsSelect}
            filterProp={primaryDriverFilter}
            driverDisabled={false}
            driverOptionDisabled={primaryDriverOptionsDisabled}
          />

          {/* SECONDARY */}
          <DriverFilterSelector
            id="SECONDARY_FILTER"
            driver={secondaryDriverSelect}
            driverList={secondaryDriverList}
            driverOptionsList={secondaryOptionsList}
            driverOptionsSelect={secondaryDriverOptionsSelect}
            filterProp={secondaryDriverFilter}
            driverDisabled={secondaryDriverDisabled}
            driverOptionDisabled={secondaryDriverOptionsDisabled}
          />

          <Inline space="small" collapseBelow="tablet">
            <Box style={{ minWidth: '155px' }}>
              <Button
                loading={isLoading}
                variant="solid"
                type="submit"
                size="standard"
                tone="formAccent"
              >
                {t('Apply filters')}
              </Button>
            </Box>
            <Button
              variant="transparent"
              type="button"
              onClick={handleClearForm}
              tone="formAccent"
            >
              {t('Clear all')}
            </Button>
          </Inline>
        </Stack>
      </Box>
    </form>
  );
};
