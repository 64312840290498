import {
  BraidSizes,
  Theme,
  type ContainersFragment,
  type CustomDecoration,
  type GetPagesQuery,
  type Locale,
} from '@seek/cmsu-cms-connect';
import type { Country, Language } from '@seek/melways-sites';
import {
  Box,
  ContentBlock,
  Divider,
  HiddenVisually,
  PageBlock,
} from 'braid-design-system';
import React from 'react';

import { CMSUProvider } from '../../hooks/useCMSUContext';
import {
  BannerRenderer,
  BlockItemRenderer,
  type BannerRenderers,
  type BlockItemRenderers,
} from '../../renderer';
import { universal } from '../../renderer/Banner/renderers';
import { DecorationRenderer } from '../BannerItem/decorations/DecorationRenderer';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { getBreadcrumbItems } from '../Breadcrumbs/breadcrumbHelper';
import { PromotionalBanner } from '../PromotionalBanner/PromotionalBanner';
import { SectionMenu } from '../SectionMenu/SectionMenu';
import { SubscriptionForm } from '../SubscriptionForm/SubscriptionForm';
import { Tiles, getTileProps } from '../Tiles/Tiles';
import {
  getBlockBorderProps,
  getBoxBackgroundProps,
  hasDivider,
} from './helper';
import { getBg } from './getBg';

interface BoxWithDecoratorProps extends React.ComponentProps<typeof Box> {
  customDecoration?: CustomDecoration | null;
}

const BoxWithDecorator = ({
  customDecoration,
  children,
  ...boxProps
}: BoxWithDecoratorProps) => (
  <Box {...boxProps} position={'relative'}>
    {customDecoration && (
      <DecorationRenderer decorationType={customDecoration} />
    )}
    <Box zIndex={1} position={'relative'}>
      {children}
    </Box>
  </Box>
);

export const PageComponent = ({
  bannerRenderers = universal,
  country,
  enabledSubscriptionForm,
  fullURL,
  language,
  melwaysLocale,
  page,
  renderers,
  subscriptionFormSourceName,
  wptSalesForceApi,
  utmParameters,
}: {
  bannerRenderers?: BannerRenderers;
  country: Country;
  enabledSubscriptionForm?: boolean;
  fullURL: string;
  language: Language;
  melwaysLocale: string;
  page: GetPagesQuery['pages'][0];
  renderers: BlockItemRenderers;
  subscriptionFormPrivacyUrl: string;
  subscriptionFormSourceName: string;
  wptSalesForceApi: string;
  utmParameters?: string;
}) => {
  const {
    title,
    promotionalBanner,
    banner,
    containers,
    relatedPageSection,
    enableBreadcrumbs,
  } = page;
  const {
    headerOptions,
    parentSection,
    sectionName,
    displayName: sectionDisplayName,
    theme,
  } = relatedPageSection || {};
  const subNavLinks =
    headerOptions?.headerType?.__typename === 'SeekHeader' &&
    headerOptions.headerType?.subnavLinks;

  const items = getBreadcrumbItems({
    locale: melwaysLocale as Locale,
    parentSection: parentSection?.sectionName || '',
    section: sectionName || '',
    title,
    sectionDisplayName,
    parentSectionDisplayName: parentSection?.displayName,
  });

  return (
    <CMSUProvider
      config={{
        language,
        country,
        utmParameters,
      }}
    >
      {subNavLinks ? (
        <SectionMenu currentPage={title} links={subNavLinks} />
      ) : null}
      <Box background="surface">
        <HiddenVisually>
          <h1>{title}</h1>
        </HiddenVisually>
        {/* Promotional Banner */}
        {promotionalBanner && <PromotionalBanner {...promotionalBanner} />}
        {/* Banner */}
        {banner && (
          <BannerRenderer
            renderers={bannerRenderers}
            banner={banner}
            country={country}
            theme={theme || Theme.SeekJobs}
          />
        )}
        {enableBreadcrumbs && (
          <PageBlock width="full">
            <Breadcrumbs items={items} />
          </PageBlock>
        )}
        {/* Containers */}
        {(containers as Array<ContainersFragment>).map(
          ({ id, box, htmlId, tile, blocks }, containerIdx) => (
            <React.Fragment key={id}>
              <BoxWithDecorator
                customDecoration={box?.customDecoration}
                textAlign={box?.textAlign || 'left'}
                paddingX={{
                  mobile: 'none',
                  tablet: 'none',
                  desktop: box?.paddingWidth || 'medium',
                }}
                className="sk-container"
                paddingY={box?.paddingHeight || 'xlarge'}
                id={htmlId || undefined}
                {...getBoxBackgroundProps(box?.background)}
              >
                <ContentBlock width={box?.width || 'medium'}>
                  <Box
                    {...getBlockBorderProps(box?.blockBorder)}
                    borderRadius={{
                      mobile: 'none',
                      tablet: box?.borderRadius || undefined,
                    }}
                    paddingX={box?.blockBorder ? 'gutter' : 'none'}
                    paddingY={box?.blockBorder ? 'medium' : 'none'}
                  >
                    <Tiles {...getTileProps(tile)}>
                      {/* Blocks */}
                      {blocks.map((block, _index) => {
                        const paddingProps = block.box?.background
                          ? {
                              paddingX: {
                                mobile: 'medium',
                                tablet: block.box?.paddingWidth || 'xlarge',
                              },
                              paddingY: block.box?.paddingHeight || 'xlarge',
                            }
                          : {
                              paddingX: {
                                mobile: 'small',
                                tablet: 'small',
                                desktop: block.box?.paddingWidth || 'none',
                              },
                              paddingY: block.box?.paddingHeight || undefined,
                            };

                        return (
                          // If the block is the first block in the first container on the page, use the container's paddingHeight.
                          // This enables us set the overall Page paddingY accurately.
                          <Box
                            key={block.id}
                            paddingY={
                              containerIdx === 0
                                ? box?.paddingHeight ||
                                  block.box?.paddingHeight ||
                                  'small' // Handle the value being 'null'
                                : block.box?.paddingHeight || 'small'
                            }
                          >
                            <BoxWithDecorator
                              customDecoration={block.box?.customDecoration}
                              textAlign={
                                block.box?.textAlign || box?.textAlign || 'left'
                              }
                              // Keep the padding to 'none' here when there is no background.
                              // Changing it will break alignment of tiles. To add padding to the blocks, adjust the padding of the parent box ^.
                              {...(paddingProps as React.ComponentProps<
                                typeof Box
                              >)}
                              className="sk-block"
                              {...getBoxBackgroundProps(block.box?.background)}
                              borderRadius={{
                                desktop: block.box?.borderRadius || 'none',
                                mobile: 'none',
                              }}
                            >
                              <ContentBlock
                                width={block.box?.width || 'medium'}
                              >
                                <Tiles
                                  {...getTileProps(block.tile)}
                                  space={block.tile?.space || BraidSizes.Large}
                                >
                                  {block.items.map((item, idx) => (
                                    <Box
                                      background={
                                        block.tile?.background || undefined
                                      }
                                      borderRadius={
                                        block.tile ? 'large' : 'none'
                                      }
                                      height="full"
                                      key={idx}
                                      className="sk-block-item"
                                    >
                                      {block.box?.background &&
                                        block.box?.background === 'customBg' &&
                                        getBg()}

                                      <BlockItemRenderer
                                        block={item}
                                        renderers={renderers}
                                        additionalData={{
                                          page,
                                          fullURL,
                                        }}
                                      />
                                    </Box>
                                  ))}
                                </Tiles>
                              </ContentBlock>
                            </BoxWithDecorator>
                          </Box>
                        );
                      })}
                    </Tiles>
                  </Box>
                </ContentBlock>
              </BoxWithDecorator>

              {/* Divider */}
              {hasDivider(containers, containerIdx) && (
                <ContentBlock width="medium">
                  <Box textAlign="left" style={{ margin: 'auto' }}>
                    <Divider />
                  </Box>
                </ContentBlock>
              )}
            </React.Fragment>
          ),
        )}
        {enabledSubscriptionForm && (
          <Box
            marginTop={{
              mobile: 'gutter',
              tablet: 'xlarge',
              desktop: 'xxlarge',
            }}
            paddingBottom={{
              mobile: 'gutter',
              tablet: 'xlarge',
              desktop: 'xxlarge',
            }}
          >
            <SubscriptionForm
              type={sectionName!}
              locale={melwaysLocale}
              country={country}
              sourceName={subscriptionFormSourceName}
              wptSalesForceApi={wptSalesForceApi}
            />
          </Box>
        )}
      </Box>
    </CMSUProvider>
  );
};
