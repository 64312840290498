import type * as React from 'react';
import type { ArticleAdditionalData } from 'src/client/pages/Article';
import { useConfig } from 'src/client/shared/hooks';
import { FastestGrowingSalaries } from './FastestGrowingSalaries/FastestGrowingSalaries';
import { GenerationalDriversSummary } from './GenerationalDrivers/GenerationalDrivers';
import { GenerationalDriversInteractive } from './GenerationalDrivers/GenerationalDriversInteractive';
import { HighestPayingJobs } from './HighestPayingJobs/HighestPayingJobs';
import { EmbededQueryFilterTAL } from './QueryFilterTAL/EmbededQueryFilterTAL';

export type CustomContentTitle =
  | 'FASTEST_GROWING_SALARIES'
  | 'HIGHEST_PAYING_JOBS'
  | 'GENERATION_DRIVERS_SUMMARY'
  | 'GENERATION_DRIVERS_INTERACTIVE'
  | 'QUERY_FILTER_TAL';
interface Props {
  title: string;
  additionalData?: ArticleAdditionalData;
}
export const CustomContent = ({ title, additionalData }: Props) => {
  const { country } = useConfig();
  const content = CustomContentMapping[title] ? (
    CustomContentMapping[title](country, additionalData)
  ) : (
    <div>--</div> // Fallback if no content is found
  );
  return <>{content}</>;
};

export const CustomContentMapping: {
  [key: string]: (
    country: string,
    additionalData?: ArticleAdditionalData,
  ) => React.ReactNode;
} = {
  FASTEST_GROWING_SALARIES: () => <FastestGrowingSalaries />,
  HIGHEST_PAYING_JOBS: () => <HighestPayingJobs />,
  GENERATION_DRIVERS_SUMMARY: (country: string) => (
    <GenerationalDriversSummary country={country} />
  ),
  GENERATION_DRIVERS_INTERACTIVE: (country: string) => (
    <GenerationalDriversInteractive country={country} />
  ),
  QUERY_FILTER_TAL: (
    country: string,
    additionalData?: ArticleAdditionalData,
  ) => <EmbededQueryFilterTAL datalabSurvey={additionalData?.datalabSurvey} />,
};
