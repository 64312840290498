import type { Locale } from '@seek/melways-sites';
import { createContext, useContext, useState } from 'react';

type DatalabContext = {
  fullURL: string;
  fullURLWithQueryParams: string;
  locale: Locale | undefined;
  dataTranslations: Map<string, string>;
  setLocale: (value: Locale) => void;
  setDataTranslations: (value: Map<string, string>) => void;
};

export const DatalabContext = createContext<DatalabContext>({
  fullURL: '',
  fullURLWithQueryParams: '',
  locale: undefined,
  dataTranslations: new Map<string, string>(),
  setLocale: () => {},
  setDataTranslations: () => {},
});

export const DatalabProvider = ({
  siteLocale,
  datalabTranslations,
  fullURL,
  fullURLWithQueryParams,
  children,
}: {
  siteLocale: Locale;
  datalabTranslations: Map<string, string>;
  children: React.ReactNode;
} & Pick<DatalabContext, 'fullURL' | 'fullURLWithQueryParams'>) => {
  const [locale, setLocale] = useState<DatalabContext['locale']>(siteLocale);
  const [dataTranslations, setDataTranslations] =
    useState<DatalabContext['dataTranslations']>(datalabTranslations);

  return (
    <DatalabContext.Provider
      value={{
        locale,
        setLocale,
        dataTranslations,
        setDataTranslations,
        fullURL,
        fullURLWithQueryParams,
      }}
    >
      {children}
    </DatalabContext.Provider>
  );
};

export const useDatalab = () => {
  const context = useContext(DatalabContext);
  if (!context) {
    throw new Error('useDatalab must be used within a DatalabProvider');
  }
  return context;
};
