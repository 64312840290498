import { Box } from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { SeekQuarterDonutShape } from '../images/SeekQuarterDonutShape';
import { SeekQuarterShape } from '../images/SeekQuarterShape';

export const TALDecoration2 = () => {
  const { isMobile } = useScreenSize();
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        maxWidth: vars.contentWidth.large,
        height: '100%',
        inset: 0,
        overflow: 'hidden',
      }}
    >
      <Box
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          borderTopRightRadius: '16px',
          overflow: 'hidden',
          transform: isMobile ? 'translateX(50%)' : 'translateX(0%)',
        }}
        position={'absolute'}
      >
        <SeekQuarterShape color={vars.backgroundColor.formAccent} />
      </Box>
      <Box
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          height: '100px',
        }}
      >
        <SeekQuarterDonutShape color={vars.backgroundColor.formAccent} />
      </Box>
    </div>
  );
};
