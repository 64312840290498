import { Box } from 'braid-design-system';

import {
  careersRibbonsAnimated,
  careersRibbons1,
  careersRibbons2,
  careersRibbons3,
  careersRibbons4,
} from './styles.css';

const getClass: Record<number, Record<'left' | 'right', string>> = {
  0: careersRibbonsAnimated,
  1: careersRibbons1,
  2: careersRibbons2,
  3: careersRibbons3,
  4: careersRibbons4,
};

export const CareersRibbonsDecoration = ({ number }: { number: number }) => (
  <Box>
    <Box
      data={{ testid: 'careersRibbons__left' }}
      className={getClass[number].left}
    />
    <Box
      data={{ testid: 'careersRibbons__right' }}
      className={getClass[number].right}
    />
  </Box>
);
