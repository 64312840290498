import type { Article } from '@seek/cmsu-cms-connect';
import { Badge, Box, Divider, Link, Stack, Text } from 'braid-design-system';
import React from 'react';
import { interactive } from './styles.css';

type Props = {
  article: Article;
  articleLink: string;
  articleItemFooterText: string;
  hideCategory?: boolean;
  isLastItem?: boolean;
};

export const ArticleListItem = ({
  article,
  articleLink,
  articleItemFooterText,
  hideCategory = false,
  isLastItem = false,
}: Props) => (
  <Stack space="gutter">
    <Box
      borderRadius="large"
      display="flex"
      style={{
        gap: '24px',
      }}
    >
      <Box
        data={{ testid: 'ArticleFeatureImageTestId' }}
        component="img"
        src={article.featureImage[0]?.url}
        style={{
          width: '200px',
          borderRadius: '6px',
        }}
      />
      <Box
        display="flex"
        justifyContent="spaceBetween"
        flexDirection={'column'}
        padding={{ mobile: 'gutter', tablet: 'none' }}
      >
        <Box paddingBottom="large">
          <Stack space="gutter">
            {!hideCategory && article.category && (
              <Badge tone="neutral" data={{ testid: 'ArticleCategoryTestId' }}>
                {article.category.name}
              </Badge>
            )}

            <Link href={articleLink} className={interactive}>
              <Text size="large" weight="strong">
                {article.title}
              </Text>
            </Link>
          </Stack>
        </Box>
        <Text tone="secondary" size="small">
          <div suppressHydrationWarning>{articleItemFooterText}</div>
        </Text>
      </Box>
    </Box>

    {!isLastItem && <Divider weight="regular" />}
  </Stack>
);
