import React from 'react';
import { Box, Text, ContentBlock, Divider } from 'braid-design-system';

export const RootSiteFooter = () => (
  <Box background="body">
    <ContentBlock width="large">
      <Box paddingX={{ mobile: 'small', wide: 'none' }}>
        <Divider />
        <Box paddingY="large">
          <Text
            size="xsmall"
            align={{ mobile: 'left', wide: 'right' }}
            tone="secondary"
          >
            © SEEK. All rights reserved
          </Text>
        </Box>
      </Box>
    </ContentBlock>
  </Box>
);
