import type { Icon } from '@seek/cmsu-cms-connect';
import { Card, IconPeople, Spread, Stack, Text } from 'braid-design-system';
import React from 'react';
import { renderIcon } from '../../../IconBlockGroup/renderer';
import { useDatalab } from '../../DatalabProvider';
import { datalabHelper } from '../../datalabHelper';

export interface CandidateData {
  name: string;
  value: string;
}

export interface CandidateCardProps {
  titleIcon: Icon;
  title: string;
  data: CandidateData[];
}

export const CandidateCard = ({
  titleIcon,
  title,
  data,
}: CandidateCardProps) => {
  const { dataTranslations } = useDatalab();
  return (
    <Card rounded height="full">
      <Stack space="medium">
        <Text icon={renderIcon(titleIcon) || <IconPeople />} weight="medium">
          {datalabHelper.getTranslationFor(dataTranslations, title)}
        </Text>

        <CandidateCardData data={data} />
      </Stack>
    </Card>
  );
};

interface CandidateCardDataProps {
  data: CandidateData[];
}

const CandidateCardData = ({ data }: CandidateCardDataProps) => {
  const { dataTranslations } = useDatalab();
  return (
    <Stack space="small">
      {data.map((item, index) => {
        const translatedTo = datalabHelper.getTranslationFor(
          dataTranslations,
          'to',
        );
        const translatedName = datalabHelper
          .getTranslationFor(dataTranslations, item.name)
          .replace(' to ', ` ${translatedTo} `);
        return (
          <Spread space="small" key={index}>
            <Text size="small">{translatedName}</Text>
            <Text size="small">{item.value}</Text>
          </Spread>
        );
      })}
    </Stack>
  );
};
