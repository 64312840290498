import type React from 'react';
import { Box, ButtonIcon, IconArrow, IconChevron } from 'braid-design-system';
import { BackgroundColor } from '@seek/cmsu-cms-connect';

enum borderTone {
  'brandAccent' = 'borderBrandAccent',
  'brandAccentSoft' = 'borderBrandAccentLight',
  'brand' = 'borderInfo',
  'neutral' = 'borderNeutral',
  'neutralSoft' = 'borderNeutralLight',
  'cautionLight' = 'borderCautionLight',
  'criticalLight' = 'borderCriticalLight',
  'positiveLight' = 'borderPositiveLight',
  'infoLight' = 'borderInfoLight',
  'promoteLight' = 'borderPromoteLight',
  'formAccentSoft' = 'borderFormAccentLight',
}

const directionLabel = {
  left: 'Prev',
  right: 'Next',
};

interface NavButtonProps {
  direction: 'left' | 'right';
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tone?: BackgroundColor;
  icon?: 'IconChevron' | 'IconArrow';
}
export const NavButton = ({
  direction,
  onClick,
  tone = BackgroundColor.Neutral,
  icon = 'IconChevron',
}: NavButtonProps) => {
  const Icon = icon === 'IconChevron' ? IconChevron : IconArrow;

  return (
    <Box
      data={{
        testid: `carousel-nav-${directionLabel[direction].toLowerCase()}`,
      }}
      borderRadius="full"
      padding="xsmall"
      marginRight={direction === 'left' ? 'small' : 'none'}
      marginLeft={direction === 'right' ? 'small' : 'none'}
      background="surface"
      boxShadow={borderTone[tone]}
    >
      <ButtonIcon
        id={`carousel-${directionLabel[direction]}`}
        icon={<Icon tone="brandAccent" direction={direction} />}
        size="large"
        label={directionLabel[direction]}
        variant="transparent"
        bleed={true}
        onClick={onClick}
      />
    </Box>
  );
};
