import React from 'react';
import type { Article } from '@seek/cmsu-cms-connect';
import { Badge, Bleed, Box, Link, Stack, Text } from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { resizeImageFromHygraph } from '../../utils/imageHelper';

type Props = {
  article: Article;
  articleLink: string;
  articleCardFooterText: string;
  hideCategory?: boolean;
};

export const ArticleCard = ({
  article,
  articleLink,
  articleCardFooterText,
  hideCategory = false,
}: Props) => (
  <Link href={articleLink}>
    <Box
      borderRadius="large"
      padding="gutter"
      overflow="hidden"
      height="full"
      display="flex"
      flexDirection="column"
      justifyContent="spaceBetween"
      style={{
        border: `2px solid ${vars.borderColor.neutralLight}`,
        gap: vars.space.gutter,
      }}
      textAlign="left"
      background="surface"
      data={{ testid: 'CardTestId' }}
    >
      <Box data={{ testid: 'ArticleFeatureImageTestId' }}>
        <Bleed horizontal="gutter" top="gutter">
          <img
            src={resizeImageFromHygraph({
              imageUrl: article.featureImage[0]?.url,
              width: 480,
              height: 320,
            })}
            alt={article.featureImage[0]?.alt || undefined}
            style={{
              width: '100%',
              height: '160px',
              objectFit: 'cover',
              overflow: 'hidden',
              backgroundColor: vars.backgroundColor.neutralSoft,
            }}
          />
        </Bleed>
      </Box>
      <Box
        height="full"
        display="flex"
        justifyContent="spaceBetween"
        flexDirection={'column'}
      >
        <Box paddingBottom="large">
          <Stack space="gutter">
            {!hideCategory && article.category && (
              <Badge tone="neutral" data={{ testid: 'ArticleCategoryTestId' }}>
                {article.category.name}
              </Badge>
            )}
            <Text
              weight="strong"
              size="large"
              data={{ testid: 'Article_Title_TestId' }}
            >
              {article.title}
            </Text>
          </Stack>
        </Box>
        <Text
          tone="secondary"
          size="small"
          data={{ testid: 'Article_Footer_TestId' }}
        >
          <div suppressHydrationWarning>{articleCardFooterText}</div>
        </Text>
      </Box>
    </Box>
  </Link>
);
