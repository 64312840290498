import { createForm } from '@seek/forms-ui';
import { ALL_DRIVER, type DatalabQueryParams } from '../datalabHelper';
import { QueryFilter, type DriverList } from './QueryFilter';

export interface FormValues {
  primaryDriverSelect: string;
  primaryDriverOptionsSelect: string[];
  secondaryDriverSelect: string;
  secondaryDriverOptionsSelect: string[];
}

const { FormProvider, useField } = createForm<FormValues>();
export { useField };

interface Props {
  driverList: DriverList;
  queryFilterParams: DatalabQueryParams;
}

export const QueryFilterForm = ({ driverList, queryFilterParams }: Props) => {
  const initialValues: FormValues = {
    primaryDriverSelect: queryFilterParams.primaryDriver,
    primaryDriverOptionsSelect: queryFilterParams.primaryDriverOptions
      ? queryFilterParams.primaryDriverOptions
      : [],
    secondaryDriverSelect: queryFilterParams.secondaryDriver
      ? queryFilterParams.secondaryDriver
      : '',
    secondaryDriverOptionsSelect: queryFilterParams.secondaryDriverOptions
      ? queryFilterParams.secondaryDriverOptions
      : [],
  };

  return (
    <FormProvider initialValues={initialValues}>
      {({ handleSubmit, resetForm, setFieldValue }) => {
        // Dynamic form field clearing
        const clearField = (fieldName: keyof FormValues) => {
          let emptyValue;
          if (Array.isArray(initialValues[fieldName])) {
            emptyValue = [];
          } else {
            emptyValue = fieldName === 'primaryDriverSelect' ? ALL_DRIVER : '';
          }
          setFieldValue(fieldName, emptyValue);
        };

        return (
          <QueryFilter
            driverList={driverList}
            handleSubmit={handleSubmit}
            resetForm={resetForm}
            clearField={clearField}
          />
        );
      }}
    </FormProvider>
  );
};
