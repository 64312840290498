import { usePDF } from '@react-pdf/renderer';
import { PdfDoc } from './custom-template';
import type { PdfDataSummary } from './custom-template/summary';
export const useDownloadTalPdf = (
  data: PdfDataSummary[],
  language: string,
  fullURL: string,
) => {
  const [instance] = usePDF({
    document: <PdfDoc data={data} language={language} fullURL={fullURL} />,
  });
  return instance;
};
