import { useEffect } from 'react';

export const useResetLoadingOnUnload = (
  setIsLoading: (state: boolean) => void,
) => {
  useEffect(() => {
    const handleUnload = () => {
      setIsLoading(false); // Reset loading state just before navigation
    };

    // Listen for the page unload event
    window.addEventListener('unload', handleUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, [setIsLoading]);
};
