import { createForm } from '@seek/forms-ui';
import type { Country, Language } from '@seek/melways-sites';
import { VocabProvider } from '@vocab/react';
import { ContentBlock } from 'braid-design-system';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import type { ButtonStyleProps } from '../types';
import { Form } from './Form';

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  industry: string;
}

const { FormProvider, useField } = createForm<FormValues, any, Language>();

export const SubscriptionForm = ({
  type,
  locale,
  sourceName,
  country,
  wptSalesForceApi,
  submitBtnStyle,
}: {
  type: string;
  locale: string;
  sourceName: string;
  country: Country;
  wptSalesForceApi: string;
  submitBtnStyle?: ButtonStyleProps;
}) => {
  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    industry: '',
  };

  const { language } = useCMSUContext();

  return (
    <ContentBlock>
      <VocabProvider language={language}>
        <FormProvider initialValues={initialValues} language={language}>
          {(formProps) => (
            <Form
              type={type}
              locale={locale}
              useField={useField}
              country={country}
              sourceName={sourceName}
              wptSalesForceApi={wptSalesForceApi}
              submitBtnStyle={submitBtnStyle}
              {...formProps}
            />
          )}
        </FormProvider>
      </VocabProvider>
    </ContentBlock>
  );
};
